import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { messageNotification } from 'helpers';
import 'pages/settings/index.scss';

import { settingsAction } from 'pages/settings/containers/settingsContainer/actions';

const AccountSettings = (props) => {
  const { name, username, email, year_of_birth, profile_image, old_email } = props;
  const [inputs, setInputs] = useState({
    name: '',
    username: '',
    email: '',
    profile_image: '',
    year_of_birth: null,
    old_email: ''
  });

  const yearlyDropdownOptions = Array.from(new Array(new Date().getFullYear() - 1900), (val, index) => index + 1900);

  useEffect(() => {
    setInputs({
      name: name,
      username: username,
      email: email,
      profile_image: profile_image,
      year_of_birth: year_of_birth,
      old_email: old_email
    });
  }, [name, username, email, year_of_birth, profile_image, old_email]);

  const updateAccountSettings = (event) => {
    event.preventDefault();
    props.dispatch(settingsAction.updateSettings(inputs));
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
  };

  const imageChange = (event) => {
    let profile_image = event.target.files[0];
    props.dispatch(settingsAction.updateUserProfileImage(inputs, profile_image));
    profile_image = URL.createObjectURL(profile_image);
    setInputs((inputs) => ({ ...inputs, profile_image: profile_image }));
  };

  const handlePasswordReset = (event) => {
    if (inputs.email) {
      props.dispatch(settingsAction.resetUserPassword(inputs));
    } else {
      messageNotification('danger', 'Accounts Settings Update Failed', 'Email Address is required.');
    }
  };

  return (
    <Fragment>
      <form name="accounts_settings" onSubmit={updateAccountSettings} className="profile-form">
        <Form.Group controlId="name">
          <label htmlFor="name">Name</label>
          <Col sm="9">
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              value={inputs.name}
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>
        <Form.Group controlId="profile_image">
          <label htmlFor="profile_image">Profile Image</label>
          <Col sm="10" className="profile-image-holder">
            <div className="profile-image-frame">
              <img src={inputs.profile_image} alt="Profile" className="profile-image" />
            </div>
            <div className="custom-file dragable">
              <input
                type="file"
                id="profile_image"
                name="profile_image"
                onChange={imageChange}
                className="form-control"
                disabled={!inputs.year_of_birth}
              />
              <label htmlFor="profile_image" className="custom-file-label">
                {inputs.year_of_birth
                  ? 'Drop file here or click to upload'
                  : 'You must specify your birth year before you can update your profile image'}
              </label>
            </div>
            <span className="input-info">You can attach gif, jpg, png, with a maximum size of 1MB</span>
          </Col>
        </Form.Group>
        <Form.Group controlId="username">
          <label htmlFor="username">
            Username <span className="required">*</span>
          </label>
          <Col sm="9">
            <input
              type="text"
              name="username"
              id="username"
              readOnly
              className="form-control"
              value={inputs.username}
              onChange={handleInputChange}
              required
            />
          </Col>
        </Form.Group>
        <Form.Group controlId="year_of_birth" as={Row}>
          <label htmlFor="year_of_birth">Year of Birth</label>
          <Col sm="5">
            <select
              className="form-control"
              id="year_of_birth"
              name="year_of_birth"
              value={inputs.year_of_birth ? inputs.year_of_birth : ''}
              onChange={handleInputChange}
            >
              <option value=""> </option>
              {yearlyDropdownOptions.map((year, index) => {
                return (
                  <option key={`year${index}`} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
          </Col>
        </Form.Group>
        <Form.Group controlId="email" as={Row}>
          <label htmlFor="email">
            Email <span className="required">*</span>
          </label>
          <Col sm="9">
            <input
              className="form-control"
              type="email"
              name="email"
              id="email"
              value={inputs.email}
              onChange={handleInputChange}
              required
              readOnly
            />
          </Col>
        </Form.Group>
        <Form.Group controlId="password-reset" as={Row}>
          <label>Password</label>
          <Col sm="4">
            <input
              type="button"
              name="password-reset"
              value="Reset Your Password"
              onClick={handlePasswordReset}
              className="btn btn-primary btn-lg"
            />
            <p>
              <small>(Check your email account for instructions to reset your password)</small>
            </p>
          </Col>
        </Form.Group>
        <Form.Group controlId="submit" sm="md-auto">
          <label>&nbsp;</label>
          <input type="submit" value="Update" className="btn btn-primary btn-lg" />
        </Form.Group>
      </form>
    </Fragment>
  );
};

function mapStateToProps(state) {
  if (state.USER_SETTINGS.length < 1) {
    return {};
  }
  const { name, username, year_of_birth, email, profile_image, old_email } = state.USER_SETTINGS;

  return {
    name,
    username,
    year_of_birth,
    email,
    profile_image,
    old_email
  };
}

const accountSettings = connect(mapStateToProps)(AccountSettings);
export { accountSettings as AccountSettings };
