import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { notificationActions } from 'pages/notifications/containers/notifications/actions/notifications';

const NotificationsListing = (props) => {
  const { billing_notifications, dispatch } = props;

  const handleNotificationStatus = (notification_id, status) => {
    dispatch(notificationActions.updateNotificationStatus(notification_id, status));
  };

  const messages = billing_notifications
    .filter((notification) => notification.is_read === false)
    .slice(0, 3)
    .map((notification, index) => {
      return (
        <div key={index} className="alert alert-warning">
          <FontAwesomeIcon icon={Icons.faExclamationCircle} className="icon" />
          <span className="close">
            <FontAwesomeIcon
              icon={Icons.faTimes}
              onClick={() => handleNotificationStatus(notification.id, notification.is_read !== true)}
            />
          </span>
          <p>{notification.message}</p>
        </div>
      );
    });

  if (messages.length === 0) {
    return <div> </div>;
  }

  return <div>{messages}</div>;
};

function mapStateToProps(state) {
  const { billing_notifications } = state.NOTIFICATION;
  return {
    billing_notifications
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ notificationActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsListing);
