import React, { useState } from 'react';

import Slider from 'react-slick';

import { themesActions } from 'pages/themes/containers/themesContainer/actions/themes';
import ImagePreviewSlider from 'pages/themes/containers/themesContainer/components/imagePreviewSlider';
import 'pages/themes/index.scss';

const ThemePreview = (props) => {
  const { theme, currentSiteTheme, activeTheme, setActiveTheme, organization, dispatch } = props;
  const [imageModalShow, setImageModalShow] = useState(false);
  const [indexThemeImagePreview, setIndexThemeImagePreview] = useState(0);

  const handleThemeChange = (e) => {
    const { id, value } = e.target;
    setActiveTheme(value);
    dispatch(
      themesActions.updateThemeConfiguration({
        theme_id: id,
        theme_name: value,
        organization_id: organization.id
      })
    );
  };

  const settings = {
    infinite: false,
    slidesToShow: 3,
    centerPadding: '50px',
    slidesToScroll: 1,
    speed: 500,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const previewImages = theme.theme_previews.map((themePreview, index) => {
    return (
      <div
        key={index}
        onClick={() => {
          setIndexThemeImagePreview(index);
          setImageModalShow(true);
        }}
        className="theme-slide"
      >
        <span>
          <img src={themePreview.preview_image} alt={themePreview.service_name} />
          <h2 className="title">
            <span className="txt">{themePreview.service_name}</span>
          </h2>
        </span>
      </div>
    );
  });

  return (
    <div className={'theme-card ' + (theme.theme_name === currentSiteTheme ? 'active' : 'hidden')}>
      {theme.theme_name !== activeTheme ? (
        <button
          id={theme.id}
          value={theme.theme_name}
          onClick={handleThemeChange}
          type="button"
          className="btn btn-primary"
        >
          Activate
        </button>
      ) : (
        ''
      )}
      <Slider {...settings}>{previewImages}</Slider>
      <ImagePreviewSlider
        show={imageModalShow}
        themepreviews={theme.theme_previews}
        currentindex={indexThemeImagePreview}
        onHide={() => {
          setImageModalShow(false);
        }}
        size="xl"
      />
    </div>
  );
};

export default ThemePreview;
