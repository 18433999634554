import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pagination from 'react-js-pagination';

import { usersActions } from 'pages/users/containers/usersContainer/actions';
import AddUser from 'pages/users/containers/usersContainer/components/addUser';
import EditUser from 'pages/users/containers/usersContainer/components/editUser';
import PaginationLoader from 'components/loaders/PaginationLoader';
import UserListItem from 'pages/users/containers/usersContainer/components/userListing/userListItem';
import UserFilters from 'pages/users/containers/usersContainer/components/filters/userFilters';
import { getWordpressBaseUrl } from 'helpers';

const UserListing = (props) => {
  const [selectedUsers, setSelectedUsers] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [user, setUser] = useState({});
  const [timer, setTimer] = useState(null);
  const [search, setSearch] = useState('');

  const [roles, setRoles] = useState({
    super_admin: false,
    staff: false,
    learner: false,
    subscriber: false,
    edly_admin: false,
    panel_restricted: false,
    panel_user: false,
    panel_admin: false
  });

  const [userFilters, setUserFilters] = useState({
    column: 'profile__name',
    direction: 'desc asc'
  });

  const {
    current_page,
    users_info,
    validation_messages,
    dispatch,
    setAddModalShow,
    addModalShow,
    count,
    username
  } = props;

  const handleSelectedUsers = (event) => {
    let selectedUserName = event.target.value;
    let status = event.target.checked;
    setSelectedUsers({
      ...selectedUsers,
      [selectedUserName]: status
    });
    setCheckAll(false);
  };

  const handlePageChange = (page_number) => {
    setPaginationLoading(true);
    dispatch(usersActions.fetchUsers({ page_number, roles, setPaginationLoading, searchText: search }));
    setCheckAll(false);
  };

  const handleSelectAllUsers = (event) => {
    let status = event.target.checked;
    let users = {};
    users_info.forEach(function(user) {
      users[user.username] = status;
    });
    setSelectedUsers(users);
    setCheckAll(status);
  };

  const handlePasswordReset = (email) => {
    dispatch(usersActions.resetUserPassword(email));
  };

  const handleUserStatusUpdate = (username, status, wordpress_role) => {
    dispatch(usersActions.updateUserStatus(username, status, search, wordpress_role, current_page, roles));
  };

  const onSort = (columnName) => {
    const direction = userFilters.column ? (userFilters.direction === 'asc' ? 'desc' : 'asc') : 'desc';
    setUserFilters({
      ...userFilters,
      column: columnName,
      direction: direction
    });

    let order_by = columnName + ',' + direction;
    setTimer(
      setTimeout(() => {
        dispatch(usersActions.fetchUsers({ searchText: search, roles, order_by: order_by }));
      }, 400)
    );
  };

  const usersListItems = users_info.map((user, index) => {
    return (
      <UserListItem
        key={index}
        user={user}
        username={username}
        setSelectedUsers={setSelectedUsers}
        handlePasswordReset={handlePasswordReset}
        handleSelectedUsers={handleSelectedUsers}
        handleUserStatusUpdate={handleUserStatusUpdate}
        selectedUsers={selectedUsers}
        setUser={setUser}
        setEditModalShow={setEditModalShow}
      />
    );
  });

  return (
    <Fragment>
      <div className="table-header">
        <UserFilters
          current_page={current_page}
          search={search}
          roles={roles}
          timer={timer}
          setRoles={setRoles}
          setTimer={setTimer}
          setSelectedUsers={setSelectedUsers}
          setSearch={setSearch}
          selectedUsers={selectedUsers}
          users_info={users_info}
          dispatch={dispatch}
        />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th rowSpan="2" className="col-check">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="checkAll"
                  checked={checkAll === true}
                  onChange={handleSelectAllUsers}
                />
                <label className="custom-control-label" htmlFor="checkAll">
                  <span className="sr-only">All Users</span>
                </label>
              </div>
            </th>
            <th rowSpan="2">
              <span
                className={
                  userFilters.column === 'profile__name' ? 'sorter ' + userFilters.direction : 'sorter asc desc'
                }
                onClick={() => onSort('profile__name')}
              >
                Name
              </span>
            </th>
            <th rowSpan="2">
              <span
                className={userFilters.column === 'username' ? 'sorter ' + userFilters.direction : 'sorter asc desc'}
                onClick={() => onSort('username')}
              >
                Username
              </span>
            </th>
            <th rowSpan="2">
              <span
                className={userFilters.column === 'email' ? 'sorter ' + userFilters.direction : 'sorter asc desc'}
                onClick={() => onSort('email')}
              >
                Email
              </span>
            </th>
            <th rowSpan="2" className="status">
              <span>Status</span>
            </th>
            <th colSpan="3" className="roles">
              Roles
            </th>
            <th rowSpan="2">
              <div className="actions">Actions</div>
            </th>
          </tr>
          <tr>
            <th>
              <span>LMS</span>
            </th>
            {getWordpressBaseUrl() && (
              <th>
                <span className="">Discovery Admin</span>
              </th>
            )}
            <th>
              <span>Panel</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {paginationLoading ? (
            <tr>
              <td colSpan={8}>
                <PaginationLoader />
              </td>
            </tr>
          ) : (
            usersListItems
          )}
        </tbody>
      </table>

      <div className="table-footer">
        <Pagination
          activePage={current_page}
          itemsCountPerPage={10}
          totalItemsCount={count}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      </div>

      <AddUser
        dispatch={dispatch}
        show={addModalShow}
        validation_messages={validation_messages}
        searchreset={() => setSearch('')}
        onHide={() => {
          setAddModalShow(false);
          dispatch(usersActions.validateUser({}));
        }}
        size="md"
      />
      <EditUser
        dispatch={dispatch}
        user={user}
        show={editModalShow}
        validation_messages={validation_messages}
        searchreset={() => setSearch('')}
        onHide={() => {
          setEditModalShow(false);
          dispatch(usersActions.validateUser({}));
        }}
        size="md"
      />
    </Fragment>
  );
};

function mapStateToProps(state) {
  const { num_pages, current_page, count, users_info } = state.USERS;
  const { validation_messages } = state.USER_VALIDATION;
  const { username } = state.USER_SETTINGS;

  return {
    num_pages,
    current_page,
    count,
    users_info,
    validation_messages,
    username
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ usersActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListing);
