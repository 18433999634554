import { combineReducers } from 'redux';

import { userSettings, fetchUserRoles } from 'pages/settings/containers/settingsContainer/reducer';
import { mausFetch } from 'pages/dashboard/containers/monthlyActiveUsers/reducers/maus';
import {
  fetchNotifications,
  fetchServicesNotifications
} from 'pages/notifications/containers/notifications/reducers/notifications';
import { themes } from 'pages/themes/containers/themesContainer/reducers/themes';
import { fetchUsers, exportUsersData, userValidation } from 'pages/users/containers/usersContainer/reducers/users';
import { pricing } from 'pages/pricing/containers/pricingContainer/reducers/pricing';
import { fetchQuickStats } from 'pages/dashboard/containers/quickStats/reducers/quickStats';

export const rootReducer = combineReducers({
  USER_SETTINGS: userSettings,
  USER_VALIDATION: userValidation,
  USER_ROLES: fetchUserRoles,
  USERS: fetchUsers,
  MAUS: mausFetch,
  NOTIFICATION: fetchNotifications,
  SERVICES_NOTIFICATIONS: fetchServicesNotifications,
  THEMES: themes.fetchThemes,
  ORGANIZATION: themes.fetchOrganization,
  PRICING: pricing.fetchPricing,
  USERS_EXPORT_DATA: exportUsersData,
  QUICK_STATS: fetchQuickStats
});
