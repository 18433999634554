import { mausConstants } from 'pages/dashboard/containers/monthlyActiveUsers/constants';
import { mausService } from 'services';
import { messageNotification, validateLoginSession } from 'helpers';

const moment = require('moment');

export const mausActions = {
  fetchMAUS,
  requestMAUSReport
};

function fetchMAUS(payload) {
  return (dispatch) => {
    let params = getPayloadParams(payload);
    mausService
      .fetchMAUS(params)
      .then((userData) => {
        Promise.resolve(
          dispatch({
            type: mausConstants.FETCH_SUCCESS,
            payload: userData.data
          })
        );
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error fetching MAUs', error.response.data.error);
      });
  };
}

function requestMAUSReport(payload, include_users_data) {
  return (dispatch) => {
    let params = getPayloadParams(payload);
    params['include_users_data'] = include_users_data;

    mausService
      .requestMAUSReport(params)
      .then((userData) => {
        Promise.resolve(
          dispatch({
            type: mausConstants.REQUEST_REPORT,
            payload: userData
          })
        );

        document.querySelector('.btn-download-csv').click();

        messageNotification(
          'success',
          'Request Detailed Report',
          'Report has been successfully generated and ready for download'
        );
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        let error_message = 'There was an error requesting MAUs Report!';
        if (error.response.data.error) {
          error_message = error.response.data.error;
        }

        messageNotification('danger', 'MAU Report Request Failed', error_message);
      });
  };
}

function getPayloadParams(mausSettings) {
  const { maus_filter_type, maus_start_date, maus_end_date, maus_quarter, maus_year } = mausSettings;
  let params = { type: maus_filter_type };

  switch (maus_filter_type) {
    case 'yearly':
      params['year'] = maus_year;
      break;
    case 'quarterly':
      params['quarter'] = maus_quarter;
      params['year'] = maus_year;
      break;
    case 'monthly':
      params['start_date'] = moment(maus_start_date).format('M-YYYY');
      params['end_date'] = moment(maus_end_date).format('M-YYYY');
      params['year'] = maus_year;
      break;
    default:
      break;
  }

  return params;
}
