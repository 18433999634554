import { EDX_API_ENDPOINTS, WORDPRESS_API_ENDPOINTS } from 'config';
import { getPanelLmsBaseUrl, getWordpressBaseUrl } from 'helpers';

const axios = require('axios');

const fetchUsers = (payload = {}) => {
  let { page_number, searchText, roles, order_by } = payload;

  if (searchText === undefined) {
    searchText = '';
  }
  let url = getPanelLmsBaseUrl() + EDX_API_ENDPOINTS.FETCH_USERS_INFO + '?search=' + searchText;
  if (roles !== undefined) {
    let selectedRoles = {};
    selectedRoles = Object.keys(roles).filter(function(key) {
      return roles[key] === true;
    });
    url = url + '&roles=' + selectedRoles.join(',');
  }
  if (order_by !== undefined) {
    url = url + '&order_by=' + order_by;
  }
  if (page_number !== undefined) {
    url = url + '&page=' + page_number;
  }

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
};

const addNewUser = (user_data) => {
  const { username, name, year_of_birth, email, is_active, lms_role, wordpress_role, panel_role } = user_data;
  let url = getPanelLmsBaseUrl() + EDX_API_ENDPOINTS.FETCH_USERS_INFO;

  let is_staff = false,
    is_superuser = false;
  if (lms_role === 'staff') {
    is_staff = true;
  }

  let profile = { name: name };
  if (year_of_birth !== '') {
    profile['year_of_birth'] = year_of_birth;
  }

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    },
    data: {
      username,
      profile,
      email,
      is_active,
      is_staff,
      is_superuser,
      wordpress_role,
      panel_role
    }
  };
  return axios(requestOptions);
};

const addWordpressUser = (user_data) => {
  const { username, email, wordpress_role, is_active } = user_data;

  let url = getWordpressBaseUrl() + WORDPRESS_API_ENDPOINTS.CREATE_USER_ACCOUNT;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    data: {
      username,
      email,
      is_active,
      role: wordpress_role
    }
  };
  return axios(requestOptions);
};

const updateUser = (user_data) => {
  const { id, name, year_of_birth, email, is_active, lms_role, wordpress_role, panel_role } = user_data;
  let url = getPanelLmsBaseUrl() + EDX_API_ENDPOINTS.FETCH_USERS_INFO + id + '/';

  let is_staff = false,
    is_superuser = false;
  if (lms_role === 'staff') {
    is_staff = true;
  }

  let profile = { name: name };
  if (year_of_birth !== '') {
    profile['year_of_birth'] = year_of_birth;
  }

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    },
    data: {
      id,
      profile,
      email,
      is_active,
      is_staff,
      is_superuser,
      wordpress_role,
      panel_role
    }
  };
  return axios(requestOptions);
};

const updateWordpressUser = (user_data) => {
  const { username, wordpress_role, is_active } = user_data;
  let url = getWordpressBaseUrl() + WORDPRESS_API_ENDPOINTS.UPDATE_USER_ROLE;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    data: {
      username,
      is_active,
      role: wordpress_role
    }
  };
  return axios(requestOptions);
};

const resetUserPassword = (email) => {
  const url = getPanelLmsBaseUrl() + EDX_API_ENDPOINTS.RESET_USER_PASSWORD;

  let bodyFormData = new FormData();
  bodyFormData.set('email', email);

  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    headers: {
      Accept: '*/*',
      'X-CSRFToken': localStorage.getItem('lms_csrf_token'),
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    },
    data: bodyFormData,
    url
  };
  return axios(requestOptions);
};

const updateUserStatus = (username, status) => {
  let url = getPanelLmsBaseUrl() + EDX_API_ENDPOINTS.UPDATE_USERS_ACCOUNT_STATUS;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    },
    data: {
      username,
      status
    }
  };
  return axios(requestOptions);
};

const updateWordpressUserStatus = (username, status, wordpress_role) => {
  let url = getWordpressBaseUrl() + WORDPRESS_API_ENDPOINTS.UPDATE_USER_ROLE;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    data: {
      username,
      is_active: status,
      role: wordpress_role
    }
  };
  return axios(requestOptions);
};

const deactivateUsers = (selectedUsers) => {
  let url = getPanelLmsBaseUrl() + EDX_API_ENDPOINTS.UPDATE_USERS_ACCOUNT_STATUS;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    },
    data: {
      username: selectedUsers.join(','),
      status: false
    }
  };
  return axios(requestOptions);
};

const deactivateWPUsers = (selectedUsers) => {
  let url = getWordpressBaseUrl() + WORDPRESS_API_ENDPOINTS.UPDATE_USER_ROLE;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    data: {
      username: selectedUsers.join(','),
      is_active: false,
      role: 'subscriber'
    }
  };
  return axios(requestOptions);
};

const exportUsersInfo = (payload) => {
  let { searchText, roles, users } = payload;
  let url = getPanelLmsBaseUrl() + EDX_API_ENDPOINTS.FETCH_USERS_ACCOUNTS_DATA;
  url = url + '?search=' + searchText + '&export=true&username=' + users.join(',');
  if (roles !== undefined) {
    let selectedRoles = {};
    selectedRoles = Object.keys(roles).filter(function(key) {
      return roles[key] === true;
    });
    url = url + '&roles=' + selectedRoles.join(',');
  }

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
};

const validateUser = (user_data) => {
  let url = getPanelLmsBaseUrl() + EDX_API_ENDPOINTS.VALIDATE_USER_INFO;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    },
    data: user_data
  };
  return axios(requestOptions);
};

export const usersService = {
  fetchUsers,
  validateUser,
  addNewUser,
  addWordpressUser,
  updateUser,
  updateWordpressUser,
  updateUserStatus,
  updateWordpressUserStatus,
  resetUserPassword,
  deactivateUsers,
  deactivateWPUsers,
  exportUsersInfo
};
