import React from 'react';
import { Modal } from 'react-bootstrap';
import Slider from 'react-slick';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const ImagePreviewSlider = (props) => {
  const previews = props.themepreviews.map((themePreview, index) => {
    return (
      <div key={index}>
        <Modal.Title>
          <h2 className="title">{themePreview.service_name}</h2>
        </Modal.Title>
        <img src={themePreview.preview_image} alt={themePreview.service_name} />
      </div>
    );
  });

  const settings = {
    infinite: false,
    slidesToShow: 1,
    centerPadding: '50px',
    adaptiveHeight: true,
    slidesToScroll: 1,
    speed: 500,
    initialSlide: props.currentindex,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      <Modal onHide={props.onHide} show={props.show} size={props.size}>
        <Modal.Header>
          <span className="close" onClick={props.onHide}>
            <FontAwesomeIcon icon={Icons.faTimes} />
          </span>
        </Modal.Header>
        <Modal.Body className="imagePreview">
          <Slider {...settings}>{previews}</Slider>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImagePreviewSlider;
