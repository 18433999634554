const NODE_ENV = process.env.NODE_ENV;
const PANEL_BASE_DOMAIN = process.env.REACT_APP_PANEL_BASE_DOMAIN;
const PANEL_BACKEND_BASE_DOMAIN = process.env.REACT_APP_PANEL_BACKEND_BASE_DOMAIN;
const LMS_BASE_URL = process.env.REACT_APP_LMS_BASE_URL;
const ECOMMERCE_BASE_URL = process.env.REACT_APP_ECOMMERCE_BASE_URL;
const PANEL_BASE_URL = process.env.REACT_APP_PANEL_BASE_URL;
const HTTP_PROTOCOL = process.env.REACT_APP_HTTP_PROTOCOL;
const EDX_API_ENDPOINTS = process.env.REACT_APP_EDX_API_ENDPOINTS
  ? process.env.REACT_APP_EDX_API_ENDPOINTS
  : {
      MAUS_API: 'api/edly_panel/v1/monthly_active_users/',
      FETCH_USERS_INFO: 'api/edly_panel/v1/users/',
      ACCOUNTS_INFO: 'api/user/v1/accounts',
      FETCH_USERNAME: 'api/user/v1/me',
      USER_PREFERENCES: 'api/user/v1/preferences/',
      RESET_USER_PASSWORD: 'password_reset/',
      UPLOAD_USER_PROFILE_IMAGE: 'api/profile_images/v1/',
      FETCH_COUNTRY_TIMEZONES: 'user_api/v1/preferences/time_zones/',
      FETCH_USER_SESSION_INFO: 'api/edly_panel/v1/session_info/',
      UPDATE_USERS_ACCOUNT_STATUS: 'api/edly_panel/v1/accounts/status_update/',
      FETCH_USERS_ACCOUNTS_DATA: 'api/edly_panel/v1/accounts',
      VALIDATE_USER_INFO: 'api/user/v1/validation/registration',
      UPDATE_LMS_THEME: 'api/edly_panel/v1/site_themes/',
      FETCH_MONTHLY_ENROLLMENT: 'api/edly_panel/v1/monthly_enrollments',
      FETCH_MONTHLY_COURSE_COMPLETIONS: 'api/edly_panel/v1/monthly_course_completions',
      UPDATE_ECOMMERCE_THEME: 'edly_ecommerce_api/v1/site_themes/',
      FETCH_ECOMMERCE_USER_SESSION_INFO: 'edly_ecommerce_api/v1/session_info/'
    };
const PANEL_API_ENDPOINTS = process.env.REACT_APP_PANEL_API_ENDPOINTS
  ? process.env.REACT_APP_PANEL_API_ENDPOINTS
  : {
      NOTIFICATIONS_API: 'api/v1/notifications/',
      SERVICES_NOTIFICATIONS_API: 'api/v1/all_services_notifications/',
      THEMES_API: 'api/v1/themes/',
      ORGANIZATIONS_API: 'api/v1/organizations/',
      PRICING_API: 'api/v1/pricing/',
      FETCH_USER_SESSION_INFO: 'api/v1/session_info/'
    };

const WORDPRESS_API_ENDPOINTS = process.env.REACT_APP_WORDPRESS_API_ENDPOINTS
  ? process.env.REACT_APP_WORDPRESS_API_ENDPOINTS
  : {
      CREATE_USER_ACCOUNT: 'wp-json/edly-wp-routes/v1/users/create/',
      UPDATE_USER_ROLE: 'wp-json/edly-wp-routes/v1/users/update-role/',
      UPDATE_THEME: 'wp-json/edly-wp-routes/v1/themes/change'
    };

const FETCH_SERVICES_NOTIFICATION_TIMER = process.env.REACT_APP_FETCH_SERVICES_NOTIFICATION_TIMER
  ? process.env.REACT_APP_FETCH_SERVICES_NOTIFICATION_TIMER
  : 180000; // 3 * 60 * 1000 (time in milliseconds)

const SERVICES_NOTIFICATIONS_DOMAIN = process.env.REACT_APP_SERVICES_NOTIFICATIONS_DOMAIN
  ? process.env.REACT_APP_SERVICES_NOTIFICATIONS_DOMAIN
  : '.edly.com';

export {
  NODE_ENV,
  PANEL_BASE_DOMAIN,
  PANEL_BACKEND_BASE_DOMAIN,
  LMS_BASE_URL,
  ECOMMERCE_BASE_URL,
  PANEL_BASE_URL,
  HTTP_PROTOCOL,
  EDX_API_ENDPOINTS,
  PANEL_API_ENDPOINTS,
  WORDPRESS_API_ENDPOINTS,
  FETCH_SERVICES_NOTIFICATION_TIMER,
  SERVICES_NOTIFICATIONS_DOMAIN
};
