import React from 'react';
import 'pages/themes/index.scss';

import ThemesCenter from 'pages/themes/containers/themesContainer/themesCenter';

const Themes = () => {
  return (
    <div className="row">
      <h1>Themes</h1>
      <ThemesCenter />
    </div>
  );
};

export default Themes;
