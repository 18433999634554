import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { getEdlyServiceUrl } from 'helpers';
import NotificationModal from 'pages/insights/containers/analyticsContainer/components/notificationModal';

const Insights = (props) => {
  const { organization } = props;
  const googleDataStudioUrl = getEdlyServiceUrl(organization, 'google_data_studio');

  const [modalShow, setModalShow] = useState(false);
  const [notificationHide, setNotificationHide] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('hideInsightsModal')) {
      return;
    }

    setTimeout(() => {
      setModalShow(true);
    }, '1500');
  }, []);

  const handleCloseModel = () => {
    setModalShow(false);
    if (notificationHide) {
      localStorage.setItem('hideInsightsModal', true);
    }
  };

  const handleCheckboxChange = (event) => {
    event.persist();
    setNotificationHide(event.target.checked);
  };

  return (
    <div className="row">
      {googleDataStudioUrl !== '/#' ? (
        <Fragment>
          <h1>Edly Insights!</h1>
          <iframe src={googleDataStudioUrl} frameBorder="0" allowFullScreen title="Edly Insights" height="600"></iframe>
          <NotificationModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            onClose={handleCloseModel}
            handleCheckboxChange={handleCheckboxChange}
            checkboxValue={notificationHide}
          ></NotificationModal>
        </Fragment>
      ) : (
        <h1>Coming Soon!</h1>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { organization } = state.ORGANIZATION;

  return {
    organization
  };
}
export default connect(mapStateToProps)(Insights);
