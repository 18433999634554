import { themesConstants, organizationsConstants } from 'pages/themes/containers/themesContainer/constants';
import { settingConstants } from 'pages/settings/containers/settingsContainer/constants/index';
import { themesService } from 'services';
import { messageNotification, validateLoginSession, getWordpressBaseUrl, getEcommerceBaseUrl } from 'helpers';
import { settingsAction } from 'pages/settings/containers/settingsContainer/actions';

const fetchThemes = () => {
  return (dispatch) => {
    themesService
      .fetchThemes()
      .then((themes) => {
        Promise.resolve(dispatch({ type: themesConstants.FETCH_THEMES_SUCCESS, payload: themes.data }));
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        if (error.response) {
          messageNotification('danger', 'There was an error fetching site themes', error.response);
        }
      });
  };
};

const fetchOrganization = () => {
  return (dispatch) => {
    themesService
      .fetchOrganizations()
      .then((organizations) => {
        const lmsUrlExists =
          organizations.data &&
          organizations.data.results &&
          organizations.data.results[0] &&
          organizations.data.results[0].edly_services_urls &&
          organizations.data.results[0].edly_services_urls.lms;
        if (lmsUrlExists) {
          localStorage.setItem('panel_lms_base_url', organizations.data.results[0].edly_services_urls.lms);
          localStorage.setItem('edx_username', organizations.data.results[0].current_site_user);

          dispatch(settingsAction.getPanelUserRoles());
          let ecommerce_base_url = organizations.data.results[0].edly_services_urls.ecommerce;
          if (typeof ecommerce_base_url !== 'undefined' && ecommerce_base_url !== '') {
            localStorage.setItem('ecommerce_base_url', ecommerce_base_url);
          } else {
            localStorage.removeItem('ecommerce_base_url');
          }

          let wordpressUrl = organizations.data.results[0].edly_services_urls.wordpress;
          if (typeof wordpressUrl !== 'undefined' && wordpressUrl !== '') {
            localStorage.setItem('wordpress_base_url', wordpressUrl);
          } else {
            localStorage.removeItem('wordpress_base_url');
          }
        }
        Promise.resolve(
          dispatch({ type: organizationsConstants.FETCH_ORGANIZATION, payload: organizations.data }),
          dispatch({
            type: settingConstants.FETCH_USERNAME,
            payload: { username: organizations.data.results[0].current_site_user }
          })
        );
      })
      .catch((error) => {
        if (error.response) {
          messageNotification('danger', 'There was an error fetching organization information', error.response);
        }
        localStorage.removeItem('edx_username');
        dispatch({ type: settingConstants.APPLICATION_LOADED });
      });
  };
};

const updateThemeConfiguration = (payload) => {
  return (dispatch) => {
    themesService
      .updateSiteThemeConfiguration(payload)
      .then((response) => {
        if (getWordpressBaseUrl()) {
          dispatch(updateWordpressTheme(payload));
        }

        if (getEcommerceBaseUrl()) {
          dispatch(updateEcommerceTheme(payload));
        }
        Promise.resolve(dispatch(updateLMSTheme(payload))).then(
          messageNotification('success', '', 'Theme Updated Successfully')
        );
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        if (error.response) {
          messageNotification('danger', 'There was an error updating site theme', error.response);
        }
      });
  };
};

const updateLMSTheme = (payload) => {
  return (dispatch) => {
    themesService
      .updateLMSTheme(payload)
      .then((response) => {
        Promise.resolve(dispatch(fetchThemes()));
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        if (error.response) {
          messageNotification('danger', 'There was an error updating theme', error.response);
        }
      });
  };
};

const updateWordpressTheme = (payload) => {
  return (dispatch) => {
    themesService.updateWordpressTheme(payload).catch((error) => {
      if (error.response) {
        messageNotification('danger', 'There was an error updating WordPress theme', error.response.data.message);
      }
    });
  };
};

const updateEcommerceTheme = (payload) => {
  return (dispatch) => {
    themesService.updateEcommerceTheme(payload).catch((error) => {
      if (error.response) {
        messageNotification('danger', 'There was an error updating Ecommerce theme', error.response.data.message);
      }
    });
  };
};

export const themesActions = {
  fetchThemes,
  fetchOrganization,
  updateLMSTheme,
  updateThemeConfiguration
};
