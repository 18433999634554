import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import { usersActions } from 'pages/users/containers/usersContainer/actions';
import { messageNotification, getWordpressBaseUrl } from 'helpers';

const EditUser = (props) => {
  const { validation_messages } = props;
  const yearlyDropdownOptions = Array.from(new Array(new Date().getFullYear() - 1900), (val, index) => index + 1900);
  const {
    id,
    username,
    name,
    year_of_birth,
    email,
    is_staff,
    is_active,
    is_superuser,
    lms_role,
    wordpress_role,
    panel_role
  } = props.user;
  const [inputs, setInputs] = useState({
    username: '',
    name: '',
    year_of_birth: '',
    email: '',
    is_staff: true,
    is_active: true,
    is_superuser: false,
    lms_role: 'learner',
    wordpress_role: 'subscriber',
    panel_role: 'panel_restricted'
  });

  useEffect(() => {
    setInputs({
      id: id,
      username: username,
      name: name,
      year_of_birth: year_of_birth,
      email: email,
      is_staff: is_staff,
      is_active: is_active,
      is_superuser: is_superuser,
      lms_role: lms_role || 'learner',
      wordpress_role: wordpress_role || 'subscriber',
      panel_role: panel_role || 'panel_restricted'
    });
  }, [
    id,
    username,
    name,
    year_of_birth,
    email,
    is_staff,
    is_active,
    is_superuser,
    lms_role,
    wordpress_role,
    panel_role
  ]);

  const handleValidation = (event) => {
    event.persist();
    props.dispatch(usersActions.validateUser({ name: inputs.name }));
  };

  const handleInputValueChange = (event) => {
    event.persist();
    if (event.target.name === 'is_active') {
      setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.checked }));
    } else {
      setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(
      usersActions.validateUser({ name: inputs.name }, usersActions.updateUser(inputs, props.onHide, props.searchreset))
    );
  };

  const handlePasswordReset = (email) => {
    if (email) {
      props.dispatch(usersActions.resetUserPassword(email));
    } else {
      messageNotification(
        'danger',
        'There was an error resetting user password',
        'Please enter email address to reset password'
      );
    }
  };

  return (
    <div>
      <Modal onHide={props.onHide} show={props.show} size={props.size}>
        <Modal.Header>
          <Modal.Title>
            <span className="close" onClick={props.onHide}>
              <FontAwesomeIcon icon={Icons.faTimes} />
            </span>
            <strong className="title">Editing user: </strong>
            <h2>{inputs.username}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="email">
              Email<span className="required">*</span>
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className="form-control"
              onBlur={handleValidation}
              onChange={handleInputValueChange}
              value={inputs.email || ''}
              readOnly
            />
            <span className="text-error">{validation_messages['email']}</span>
          </div>
          <div className="form-group">
            <label htmlFor="username">
              Username
              <span className="required">*</span>
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="form-control"
              onBlur={handleValidation}
              onChange={handleInputValueChange}
              value={inputs.username || ''}
              readOnly
            />
            <span className="text-error">{validation_messages['username']}</span>
          </div>
          <div className="form-group">
            <label htmlFor="name">
              Full Name<span className="required">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              onBlur={handleValidation}
              onChange={handleInputValueChange}
              value={inputs.name || ''}
            />
            <span className="text-error">{validation_messages['name']}</span>
          </div>
          <div className="form-group">
            <label htmlFor="year_of_birth">Year of Birth</label>
            <select
              className="form-control"
              id="year_of_birth"
              name="year_of_birth"
              value={inputs.year_of_birth ? inputs.year_of_birth : ''}
              onChange={handleInputValueChange}
            >
              <option value=""> </option>
              {yearlyDropdownOptions.map((year, index) => {
                return (
                  <option key={`year${index}`} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group">
            <span className="btn btn-lg btn-primary" onClick={() => handlePasswordReset(inputs.email)}>
              Change Password
            </span>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                name="is_active"
                id="is_active"
                onChange={handleInputValueChange}
                value={inputs.is_active}
                defaultChecked={inputs.is_active}
              />
              <label className="custom-control-label" htmlFor="is_active">
                Active
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="admin">Edly Panel Role</label>
            <div className="roles-row">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="panel_role"
                  className="custom-control-input"
                  id="panel_restricted_filter"
                  onChange={handleInputValueChange}
                  checked={inputs.panel_role.toString() === 'panel_restricted'}
                  value="panel_restricted"
                />
                <label className="custom-control-label" htmlFor="panel_restricted_filter">
                  Restricted
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="panel_role"
                  className="custom-control-input"
                  id="panel_user_filter"
                  onChange={handleInputValueChange}
                  checked={inputs.panel_role.toString() === 'panel_user'}
                  value="panel_user"
                />
                <label className="custom-control-label" htmlFor="panel_user_filter">
                  Panel User
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="panel_role"
                  className="custom-control-input"
                  id="panel_admin_filter"
                  onChange={handleInputValueChange}
                  checked={inputs.panel_role.toString() === 'panel_admin'}
                  value="panel_admin"
                />
                <label className="custom-control-label" htmlFor="panel_admin_filter">
                  Panel Admin
                </label>
              </div>
            </div>
          </div>
          {getWordpressBaseUrl() && (
            <div className="form-group">
              <label htmlFor="admin">Discovery Role</label>
              <div className="roles-row">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    name="wordpress_role"
                    className="custom-control-input"
                    id="subscriber_filter"
                    onChange={handleInputValueChange}
                    checked={inputs.wordpress_role.toString() === 'subscriber'}
                    value="subscriber"
                  />
                  <label className="custom-control-label" htmlFor="subscriber_filter">
                    Subscriber
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    name="wordpress_role"
                    className="custom-control-input"
                    id="edly_admin_filter"
                    onChange={handleInputValueChange}
                    checked={inputs.wordpress_role.toString() === 'edly_admin'}
                    value="edly_admin"
                  />
                  <label className="custom-control-label" htmlFor="edly_admin_filter">
                    Edly Admin
                  </label>
                </div>
              </div>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="admin">LMS Role</label>
            <div className="roles-row">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="lms_role"
                  className="custom-control-input"
                  id="learner_filter"
                  onChange={handleInputValueChange}
                  checked={inputs.lms_role.toString() === 'learner'}
                  value="learner"
                />
                <label className="custom-control-label" htmlFor="learner_filter">
                  Learner
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="lms_role"
                  className="custom-control-input"
                  id="staff_filter"
                  onChange={handleInputValueChange}
                  checked={inputs.lms_role.toString() === 'staff'}
                  value="staff"
                />
                <label className="custom-control-label" htmlFor="staff_filter">
                  Staff
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="lg" onClick={props.onHide}>
            Cancel
          </Button>
          <Button variant="primary" size="lg" onClick={handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditUser;
