import React from 'react';
import 'pages/pricing/index.scss';

import PricingCenter from 'pages/pricing/containers/pricingContainer/pricingCenter';

const Pricing = () => {
  return (
    <div className="row">
      <PricingCenter />
    </div>
  );
};

export default Pricing;
