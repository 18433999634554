import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const RolesFilter = (props) => {
  const [checkAllRoles, setCheckAllRoles] = useState(false);
  const [activeSelection, setActiveSelection] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState({
    super_admin: false,
    staff: false,
    learner: false,
    subscriber: false,
    edly_admin: false,
    panel_restricted: false,
    panel_user: false,
    panel_admin: false
  });

  const { handleRolesFilter } = props;

  const handleSelectedRoles = (event) => {
    let selectedRoleName = event.target.value;
    let status = event.target.checked;
    setSelectedRoles({
      ...selectedRoles,
      [selectedRoleName]: status
    });
    selectedRoles[selectedRoleName] = status;
    handleRolesFilter(selectedRoles);
    setCheckAllRoles(false);
    checkRolesSelection(selectedRoles);
  };

  const handleSelectAllRoles = (event) => {
    let status = event.target.checked;
    let roles = {
      super_admin: status,
      staff: status,
      learner: status,
      subscriber: status,
      edly_admin: status,
      panel_restricted: status,
      panel_user: status,
      panel_admin: status
    };
    setActiveSelection(status);
    setSelectedRoles(roles);
    setCheckAllRoles(status);
    handleRolesFilter(roles);
    checkRolesSelection(roles);
  };

  const checkRolesSelection = (selectedRoles) => {
    let users = Object.keys(selectedRoles).filter(function(key) {
      return selectedRoles[key] === true;
    });
    setActiveSelection(!!users.length);
  };

  return (
    <li className={activeSelection ? 'active' : ''}>
      <NavDropdown
        title={
          <button className="btn btn-link" title="Filter on Selected Roles">
            <FontAwesomeIcon icon={Icons.faFilter} />
            <span className="text hidden-mobile">Filters</span>
          </button>
        }
      >
        <div className="search-roles">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="checkAllRoles"
              checked={checkAllRoles === true}
              onChange={handleSelectAllRoles}
            />
            <label className="custom-control-label" htmlFor="checkAllRoles">
              <strong>Roles</strong>
            </label>
          </div>
          <div className="roles-frame">
            <div className="roles-col">
              <h4>LMS</h4>
              <ul>
                <li>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="super_admin"
                      name="super_admin"
                      value="super_admin"
                      checked={selectedRoles['super_admin'] === true}
                      onChange={handleSelectedRoles}
                    />
                    <label className="custom-control-label" htmlFor="super_admin">
                      Site Admin
                    </label>
                  </div>
                </li>
                <li>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="staff"
                      name="staff"
                      value="staff"
                      checked={selectedRoles['staff'] === true}
                      onChange={handleSelectedRoles}
                    />
                    <label className="custom-control-label" htmlFor="staff">
                      Staff
                    </label>
                  </div>
                </li>
                <li>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="learner"
                      name="learner"
                      value="learner"
                      checked={selectedRoles['learner'] === true}
                      onChange={handleSelectedRoles}
                    />
                    <label className="custom-control-label" htmlFor="learner">
                      Learner
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div className="roles-col">
              <h4>Discovery Admin</h4>
              <ul>
                <li>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="subscriber"
                      name="subscriber"
                      value="subscriber"
                      checked={selectedRoles['subscriber'] === true}
                      onChange={handleSelectedRoles}
                    />
                    <label className="custom-control-label" htmlFor="subscriber">
                      Subscriber
                    </label>
                  </div>
                </li>
                <li>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="edly_admin"
                      name="edly_admin"
                      value="edly_admin"
                      checked={selectedRoles['edly_admin'] === true}
                      onChange={handleSelectedRoles}
                    />
                    <label className="custom-control-label" htmlFor="edly_admin">
                      Edly Admin
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div className="roles-col">
              <h4>Panel</h4>
              <ul>
                <li>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="panel_restricted"
                      name="panel_restricted"
                      value="panel_restricted"
                      checked={selectedRoles['panel_restricted'] === true}
                      onChange={handleSelectedRoles}
                    />
                    <label className="custom-control-label" htmlFor="panel_restricted">
                      Restricted
                    </label>
                  </div>
                </li>
                <li>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="panel_user"
                      name="panel_user"
                      value="panel_user"
                      checked={selectedRoles['panel_user'] === true}
                      onChange={handleSelectedRoles}
                    />
                    <label className="custom-control-label" htmlFor="panel_user">
                      User
                    </label>
                  </div>
                </li>
                <li>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="panel_admin"
                      name="panel_admin"
                      value="panel_admin"
                      checked={selectedRoles['panel_admin'] === true}
                      onChange={handleSelectedRoles}
                    />
                    <label className="custom-control-label" htmlFor="panel_admin">
                      Admin
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </NavDropdown>
    </li>
  );
};

export default connect()(RolesFilter);
