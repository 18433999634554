import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'pages/pricing/index.scss';

import { mausActions } from 'pages/dashboard/containers/monthlyActiveUsers/actions/maus';
import { pricingActions } from 'pages/pricing/containers/pricingContainer/actions/pricing';
import PricingPlan from 'pages/pricing/containers/pricingContainer/components/pricingPlan.jsx';

const moment = require('moment');

class PricingCenter extends Component {
  componentDidMount() {
    this.props.dispatch(pricingActions.fetchPricing());
    this.props.dispatch(
      mausActions.fetchMAUS({
        maus_filter_type: 'monthly',
        maus_start_date: moment()
          .subtract(1, 'months')
          .endOf('month'), // Previous month date
        maus_end_date: moment() // Current month date
      })
    );
  }

  render() {
    const { pricing, maus_data } = this.props;

    if (pricing === null) {
      return <div />;
    }

    const pricingTierItems = pricing.map((pricingTier, index) => {
      return (
        <li key={index}>
          {pricingTier.mau_range_start} - {pricingTier.mau_range_end}
        </li>
      );
    });

    return (
      <div className="pricing-block">
        <h1>Current Plan</h1>
        <PricingPlan pricing={pricing} maus_data={maus_data} />
        <h1>MAU Tiers</h1>
        <ul className="tier-list">{pricingTierItems}</ul>

        <h2>Pricing</h2>
        <p>
          For more information, please reach out to <a href="mailto:hello@edly.io">hello@edly.io</a>
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { pricing } = state.PRICING;
  const { maus_data } = state.MAUS;

  return {
    pricing,
    maus_data
  };
}

export default connect(mapStateToProps)(PricingCenter);
