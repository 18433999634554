import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';

import RolesFilter from 'pages/users/containers/usersContainer/components/filters/rolesFilter';
import { usersActions } from 'pages/users/containers/usersContainer/actions';
import { messageNotification } from 'helpers';

const UserFilters = (props) => {
  const {
    roles,
    setRoles,
    setSelectedUsers,
    timer,
    setTimer,
    search,
    setSearch,
    selectedUsers,
    users_info,
    users_data,
    current_page,
    dispatch
  } = props;

  const handleSearchFilter = (page_number, searchText) => {
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        dispatch(usersActions.fetchUsers({ page_number, roles, searchText }));
      }, 3000)
    );
  };

  const handleSearchChange = (event) => {
    let searchText = event.target.value;
    setSearch(searchText);
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        dispatch(usersActions.fetchUsers({ page_number: 1, roles, searchText }));
      }, 400)
    );
  };

  const handleDeactivateUsers = (e) => {
    let users = {};
    users = Object.keys(selectedUsers).filter(function(key) {
      return selectedUsers[key] === true;
    });
    if (users.length < 1) {
      messageNotification('warning', 'Select a user to deactivate', 'Please select at least one user to deactivate.');
    } else {
      dispatch(usersActions.deactivateUsers(users, search, current_page));
      users_info.forEach(function(user) {
        users[user.username] = false;
      });
      setSelectedUsers(users);
    }
  };

  const handleExportUsers = (e) => {
    let users = Object.keys(selectedUsers).filter(function(key) {
      return selectedUsers[key] === true;
    });
    dispatch(usersActions.exportUsersInfo({ searchText: search, roles, users }));
  };

  const handleRolesFilter = (rolesSelected) => {
    setRoles(rolesSelected);
    dispatch(usersActions.fetchUsers({ page_number: 1, searchText: search, roles: rolesSelected }));
  };

  return (
    <Fragment>
      <div className="search-bar">
        <input
          type="text"
          className="form-control"
          placeholder="Search users"
          name="search"
          id="search"
          value={search}
          onChange={handleSearchChange}
          aria-label="Search User"
        />
        <div className="input-group-append">
          <button
            className="btn btn-primary"
            type="button"
            id="button-addon2"
            onClick={() => handleSearchFilter(1, search)}
          >
            <FontAwesomeIcon icon={Icons.faSearch} className="fa-lg" />
            <span className="sr-only">Search User</span>
          </button>
        </div>
      </div>
      <ul className="inline-list">
        <RolesFilter handleRolesFilter={handleRolesFilter} />
        <li>
          <button className="btn btn-link" title="Export all or selected Users" onClick={handleExportUsers}>
            <FontAwesomeIcon icon={Icons.faDownload} />
            <span className="text hidden-mobile">Export</span>
          </button>
        </li>
        <li className="hidden">
          <CSVLink filename="users_data.csv" className="btn btn-link btn-download-csv" data={users_data}>
            <FontAwesomeIcon icon={Icons.faDownload} />
            <strong className="text">
              Export <span className="hidden-mobile">Users Data</span>
            </strong>
          </CSVLink>
        </li>
        <li>
          <button className="btn btn-link" title="Deactivate Selected Users" onClick={handleDeactivateUsers}>
            <FontAwesomeIcon icon={Icons.faBan} />
            <span className="text hidden-mobile">Deactivate</span>
          </button>
        </li>
      </ul>
    </Fragment>
  );
};

function mapStateToProps(state) {
  const { users_data } = state.USERS_EXPORT_DATA;

  return {
    users_data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ usersActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFilters);
