export const usersConstants = {
  FETCH_SUCCESS: 'FETCH_USERS',
  FETCH_FAILURE: 'FETCH_USERS_FAILURE',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE: 'ADD_USER_FAILURE',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  RESET_USER_PASSWORD: 'RESET_USER_PASSWORD',
  UPDATE_USER_STATUS: 'UPDATE_USER_STATUS',
  DEACTIVATE_USERS_SUCCESS: 'DEACTIVATE_USERS_SUCCESS',
  DEACTIVATE_USERS_FAILURE: 'DEACTIVATE_USERS_FAILURE',
  EXPORT_USERS_SUCCESS: 'EXPORT_USERS_SUCCESS',
  EXPORT_USERS_FAILURE: 'EXPORT_USERS_FAILURE',
  VALIDATE_USER_SUCCESS: 'VALIDATE_USER_SUCCESS',
  VALIDATE_USER_FAILURE: 'VALIDATE_USER_FAILURE',
  ADD_WP_USER_SUCCESS: 'ADD_WP_USER_SUCCESS',
  ADD_WP_USER_FAILURE: 'ADD_WP_USER_FAILURE',
  UPDATE_WP_USER_SUCCESS: 'UPDATE_WP_USER_SUCCESS',
  UPDATE_WP_USER_FAILURE: 'UPDATE_WP_USER_FAILURE',
  UPDATE_WP_USER_STATUS_SUCCESS: 'UPDATE_WP_USER_STATUS_SUCCESS',
  UPDATE_WP_USER_STATUS_FAILURE: 'UPDATE_WP_USER_STATUS_FAILURE',
  DEACTIVATE_WP_USERS_SUCCESS: 'DEACTIVATE_WP_USERS_SUCCESS',
  DEACTIVATE_WP_USERS_FAILURE: 'DEACTIVATE_WP_USERS_FAILURE'
};
