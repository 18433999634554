import { pricingConstants } from 'pages/pricing/containers/pricingContainer/constants';

const initialStatePricing = {
  pricing_next_page: null,
  pricing: []
};

const fetchPricing = (state = initialStatePricing, action) => {
  switch (action.type) {
    case pricingConstants.FETCH_PRICING_SUCCESS:
      return {
        ...state,
        pricing_next_page: action.payload.next,
        pricing: action.payload.results
      };
    case pricingConstants.FETCH_PRICING_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export const pricing = {
  fetchPricing
};
