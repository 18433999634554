import { PANEL_API_ENDPOINTS } from 'config';
import { getPanelBackendUrl } from 'helpers';

const axios = require('axios');

const fetchPricing = () => {
  const url = getPanelBackendUrl() + PANEL_API_ENDPOINTS.PRICING_API;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
};

export const pricingService = {
  fetchPricing
};
