import { mausConstants } from 'pages/dashboard/containers/monthlyActiveUsers/constants';

const initialState = {
  maus_data_labels: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  maus_data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  maus_filter_type: 'yearly',
  maus_start_date: '',
  maus_end_date: '',
  maus_quarter: 1,
  maus_year: new Date().getFullYear(),
  maus_detailed_report: []
};

export function mausFetch(state = initialState, action) {
  switch (action.type) {
    case mausConstants.REQUEST_REPORT:
      return {
        ...state,
        maus_detailed_report: action.payload.data.data.detailed_report
      };
    case mausConstants.FETCH_SUCCESS:
      return {
        ...state,
        maus_data_labels: action.payload.data.month_names,
        maus_data: action.payload.data.monthly_users_count
      };
    case mausConstants.FETCH_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
