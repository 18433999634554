import React, { Fragment } from 'react';
import ReactNotification from 'react-notifications-component';
import { Redirect, Route, Switch } from 'react-router-dom';

import AppRoute from 'components/routes/AppRoute';
import PublicRoute from 'components/routes/PublicRoute';
import ProtectedLayout from 'components/layouts/ProtectedLayout';
import PublicLayout from 'components/layouts/PublicLayout';
import Dashboard from 'pages/dashboard';
import Notifications from 'pages/notifications';
import Pricing from 'pages/pricing';
import Insights from 'pages/insights';
import Themes from 'pages/themes';
import Settings from 'pages/settings';
import Help from 'pages/help';
import Users from 'pages/users';
import SeeAllApps from 'pages/seeAllApps';
import Page401 from 'pages/401';
import Login from 'pages/login';
import Page404 from 'pages/404';

const Routes = (props) => {
  return (
    <Fragment>
      <ReactNotification />
      <Switch>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        <AppRoute path="/dashboard" component={Dashboard} layout={ProtectedLayout} panel_role={props.panel_role} />
        <AppRoute
          path="/notifications"
          component={Notifications}
          layout={ProtectedLayout}
          panel_role={props.panel_role}
        />
        <AppRoute path="/pricing" component={Pricing} layout={ProtectedLayout} panel_role={props.panel_role} />
        <AppRoute path="/insights" component={Insights} layout={ProtectedLayout} panel_role={props.panel_role} />
        <AppRoute path="/themes" component={Themes} layout={ProtectedLayout} panel_role={props.panel_role} />
        <AppRoute path="/settings" component={Settings} layout={ProtectedLayout} panel_role={props.panel_role} />
        <AppRoute path="/help" component={Help} layout={ProtectedLayout} panel_role={props.panel_role} />
        <AppRoute path="/users" component={Users} layout={ProtectedLayout} panel_role={props.panel_role} />
        <AppRoute path="/see_all_apps" component={SeeAllApps} layout={ProtectedLayout} panel_role={props.panel_role} />
        <AppRoute path="/401" component={Page401} layout={PublicLayout} panel_role={props.panel_role} />
        <PublicRoute path="/login" component={Login} layout={PublicLayout} />
        <PublicRoute component={Page404} layout={PublicLayout} />
      </Switch>
    </Fragment>
  );
};

export default Routes;
