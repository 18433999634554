import { settingConstants } from 'pages/settings/containers/settingsContainer/constants/index';

const initialState = {
  name: '',
  username: '',
  email: '',
  year_of_birth: null,
  organization: '',
  profile_image: '',
  country_code: 'PK',
  country_timezones: [],
  time_zone: '',
  site_logo: '',
  old_email: '',
  is_loading: true
};

const initialUserRolesState = {
  roles: {
    panel_role: 'panel_restricted'
  }
};

export const userSettings = (state = initialState, action) => {
  switch (action.type) {
    case settingConstants.UPDATE_SETTINGS:
      return {
        ...state,
        ...action.payload
      };
    case settingConstants.FETCH_SETTINGS:
      return {
        ...state,
        ...action.payload
      };
    case settingConstants.FETCH_USERNAME:
      return {
        ...state,
        ...action.payload
      };
    case settingConstants.FETCH_USER_PREFERENCES:
      return {
        ...state,
        ...action.payload
      };
    case settingConstants.FETCH_COUNTRY_TIMEZONES:
      return {
        ...state,
        country_timezones: action.payload
      };
    case settingConstants.UPDATE_USER_PREFERENCES:
      return {
        ...state,
        ...action.payload
      };
    case settingConstants.USER_SESSION_LOGOUT:
      return {
        ...state,
        ...action.payload
      };
    case settingConstants.APPLICATION_LOADING:
      return {
        ...state,
        is_loading: true
      };
    case settingConstants.APPLICATION_LOADED:
      return {
        ...state,
        is_loading: false
      };
    case settingConstants.USER_SETTINGS_FAILURE:
      return {
        ...initialState,
        is_loading: false
      };
    default:
      return state;
  }
};

export const fetchUserRoles = (state = initialUserRolesState, action) => {
  switch (action.type) {
    case settingConstants.FETCH_USER_ROLES:
      return {
        ...state,
        roles: action.payload ? action.payload['roles'] : initialUserRolesState['roles']
      };
    case settingConstants.FETCH_USER_ROLES_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
