import { themesConstants, organizationsConstants } from 'pages/themes/containers/themesContainer/constants';

const initialStateThemes = {
  themes_next_page: null,
  themes: []
};

const initialStateOrganization = {
  organization: null
};

const fetchThemes = (state = initialStateThemes, action) => {
  switch (action.type) {
    case themesConstants.FETCH_THEMES_SUCCESS:
      return {
        ...state,
        themes_next_page: action.payload.next,
        themes: action.payload.results
      };
    case themesConstants.FETCH_THEMES_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

const fetchOrganization = (state = initialStateOrganization, action) => {
  switch (action.type) {
    case organizationsConstants.FETCH_ORGANIZATION:
      return {
        ...state,
        organization: action.payload.results[0]
      };
    case organizationsConstants.FETCH_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export const themes = {
  fetchThemes,
  fetchOrganization
};
