import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';

import 'pages/settings/index.scss';
import { settingsAction } from 'pages/settings/containers/settingsContainer/actions';

const countryList = require('country-list');

const UserPreferences = (props) => {
  const { username, organization, country_code, time_zone, country_timezones, dispatch } = props;
  const [inputs, setInputs] = useState({
    username: '',
    organization: '',
    country_code: '',
    time_zone: ''
  });

  const countries = countryList.getData().map((country, index) => {
    return (
      <option key={index} value={country.code}>
        {country.name}
      </option>
    );
  });

  useEffect(() => {
    setInputs({
      username: username,
      organization: organization,
      country_code: country_code,
      time_zone: time_zone
    });
  }, [username, organization, country_code, time_zone, dispatch]);

  const handleInputChange = (event) => {
    event.persist();

    if (event.target.name === 'country_code') {
      dispatch(settingsAction.getCountryTimezones(event.target.value));
      setInputs((inputs) => ({ ...inputs, time_zone: '' }));
    }
    setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
  };

  const updateUserPreferences = (e) => {
    e.preventDefault();
    dispatch(settingsAction.updateUserPreferences(inputs));
  };

  return (
    <Fragment>
      <form name="user_preferences" onSubmit={updateUserPreferences} className="profile-form">
        <Form.Group controlId="country_code" as={Row}>
          <label>Country</label>
          <Col sm="5">
            <select
              className="form-control"
              id="country_code"
              name="country_code"
              value={inputs.country_code ? inputs.country_code : ''}
              onChange={handleInputChange}
            >
              {countries}
            </select>
          </Col>
        </Form.Group>

        <Form.Group controlId="time_zone" as={Row}>
          <label>Timezone</label>
          <Col sm="5">
            <select
              className="form-control"
              id="time_zone"
              name="time_zone"
              value={inputs.time_zone ? inputs.time_zone : ''}
              onChange={handleInputChange}
            >
              <option value=""> </option>
              {country_timezones.map((country_timezone, index) => {
                return (
                  <option key={`year${index}`} value={country_timezone.time_zone}>
                    {country_timezone.description}
                  </option>
                );
              })}
            </select>
          </Col>
        </Form.Group>

        <Form.Group controlId="organization" as={Row}>
          <label>Organization</label>
          <Col sm="9">
            <Form.Control
              type="text"
              name="organization"
              value={inputs.organization || ''}
              onChange={handleInputChange}
              readOnly
            />
          </Col>
        </Form.Group>

        <Form.Group controlId="submit" sm="md-auto">
          <label>&nbsp;</label>
          <input type="submit" value="Update" className="btn btn-primary btn-lg" />
        </Form.Group>
      </form>
    </Fragment>
  );
};

function mapStateToProps(state) {
  const { username, country_code, time_zone, country_timezones } = state.USER_SETTINGS;
  let { organization } = state.ORGANIZATION;
  if (organization) {
    organization = organization.name;
  }

  return {
    username,
    organization,
    country_code,
    time_zone,
    country_timezones
  };
}

const userPreferences = connect(mapStateToProps)(UserPreferences);
export { userPreferences as UserPreferences };
