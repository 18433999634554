import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import 'pages/settings/index.scss';

import { AccountSettings } from 'pages/settings/containers/settingsContainer/components/accountSettings.jsx';
import { UserPreferences } from 'pages/settings/containers/settingsContainer/components/userPreferences.jsx';

const Settings = () => {
  const [currentTab, setCurrentTab] = useState('account_settings');
  return (
    <div className="row">
      <Col md={10}>
        <h1>Settings</h1>
        <div className="tabs" id="accountSettings">
          <ul className="tab-links">
            <li
              onClick={() => setCurrentTab('account_settings')}
              className={currentTab === 'account_settings' ? 'active' : ''}
            >
              <span>Account Settings</span>
            </li>
            <li
              onClick={() => setCurrentTab('user_preferences')}
              className={currentTab === 'user_preferences' ? 'active' : ''}
            >
              <span>User Preferences</span>
            </li>
          </ul>
          <div className="tab-content">
            <div id="div_accounts_settings" className={currentTab === 'user_preferences' ? 'hidden' : ''}>
              <AccountSettings />
            </div>

            <div id="div_user_preferences" className={currentTab === 'account_settings' ? 'hidden' : ''}>
              <UserPreferences />
            </div>
          </div>
        </div>
      </Col>
    </div>
  );
};

export default Settings;
