import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { notificationActions } from 'pages/notifications/containers/notifications/actions/notifications';
import Pagination from 'react-js-pagination';
const moment = require('moment');

const NotificationsCenter = (props) => {
  const { notification_center, dispatch } = props;

  const handleNotificationStatus = (notification_id, status) => {
    dispatch(notificationActions.updateNotificationStatus(notification_id, status, props.current_page));
  };

  const handlePageChange = (page_number) => {
    dispatch(notificationActions.fetchNotifications(page_number));
  };

  const messages = notification_center.map((notification, index) => {
    return (
      <div className={notification.is_read === true ? 'notification read' : 'notification unread'} key={index}>
        <h4 className="title">{notification.message}</h4>
        <ul className="inline-list">
          <li>
            <strong>{moment(notification.created).format('MM-DD-YYYY')}</strong>
          </li>
          <li>
            <button className="btn btn-link">
              <FontAwesomeIcon
                icon={notification.is_read === false ? Icons.faEnvelope : Icons.faEnvelopeOpen}
                onClick={() => handleNotificationStatus(notification.id, notification.is_read !== true)}
                title={notification.is_read === false ? 'Read Notification' : 'Unread Notification'}
              />
            </button>
          </li>
        </ul>
      </div>
    );
  });

  return (
    <div>
      <h1>Notifications</h1>
      <div className={`notification-list ${messages.length > 0 ? '' : 'hidden'}`}>{messages}</div>
      <div className={`notification-footer ${messages.length > 0 ? '' : 'hidden'}`}>
        <Pagination
          activePage={props.current_page}
          itemsCountPerPage={100}
          totalItemsCount={props.count}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { notification_center, count, current_page } = state.NOTIFICATION;
  return {
    notification_center,
    count,
    current_page
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ notificationActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsCenter);
