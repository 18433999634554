import { usersConstants } from 'pages/users/containers/usersContainer/constants';

const initialStateFetchUsers = {
  num_pages: 1,
  current_page: 1,
  count: 0,
  users_info: []
};

const initialStateExportUsers = {
  users_data: []
};

const initialStateValidateUser = {
  validation_messages: {}
};

const initialStateUserProfile = {
  username: '',
  name: '',
  year_of_birth: '',
  email: '',
  is_staff: true,
  is_active: true,
  is_superuser: false,
  lms_role: 'learner',
  wordpress_role: 'subscriber',
  panel_role: 'panel_restricted'
};

export function fetchUsers(state = initialStateFetchUsers, action) {
  switch (action.type) {
    case usersConstants.FETCH_SUCCESS:
      let users_info = [];
      action.payload.results.forEach((user) => {
        users_info.push({
          id: user.id,
          username: user.username,
          name: user.profile ? user.profile.name : '',
          year_of_birth: user.profile ? user.profile.year_of_birth : '',
          email: user.email,
          is_staff: user.is_staff,
          is_active: user.is_active,
          is_superuser: user.is_superuser,
          lms_role: user.roles.lms_role,
          wordpress_role: user.roles.wordpress_role,
          panel_role: user.roles.panel_role
        });
      });

      return {
        ...state,
        num_pages: action.payload.num_pages,
        current_page: action.payload.current_page,
        count: action.payload.count,
        users_info: users_info
      };
    case usersConstants.FETCH_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

export function userProfile(state = initialStateUserProfile, action) {
  switch (action.type) {
    case usersConstants.ADD_USER_SUCCESS:
      return {
        ...state,
        username: action.payload.username,
        name: action.payload.profile ? action.payload.profile.name : '',
        year_of_birth: action.payload.profile ? action.payload.profile.year_of_birth : '',
        email: action.payload.email,
        is_staff: action.payload.is_staff,
        is_active: action.payload.is_active,
        is_superuser: action.payload.is_superuser,
        lms_role: action.payload.roles.lms_role,
        wordpress_role: action.payload.roles.wordpress_role,
        panel_role: action.payload.roles.panel_role
      };
    case usersConstants.ADD_USER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case usersConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case usersConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case usersConstants.ADD_WP_USER_SUCCESS:
      return {
        ...state
      };
    case usersConstants.ADD_WP_USER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case usersConstants.UPDATE_WP_USER_SUCCESS:
      return {
        ...state
      };
    case usersConstants.UPDATE_WP_USER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case usersConstants.UPDATE_WP_USER_STATUS_SUCCESS:
      return {
        ...state
      };
    case usersConstants.UPDATE_WP_USER_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case usersConstants.DEACTIVATE_WP_USERS_SUCCESS:
      return {
        ...state
      };
    case usersConstants.DEACTIVATE_WP_USERS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

export function exportUsersData(state = initialStateExportUsers, action) {
  switch (action.type) {
    case usersConstants.EXPORT_USERS_SUCCESS:
      let users_data = [['Username', 'Name', 'Email', 'Status', 'LMS Role', 'Discovery Admin Role', 'Panel Role']];
      action.payload.data.forEach((user) => {
        users_data.push([
          user.username,
          user.profile ? user.profile.name : '',
          user.email,
          user.is_active ? 'Active' : 'InActive',
          user.roles.lms_role === 'super_admin' ? 'site admin' : user.roles.lms_role.replace('_', ' '),
          user.roles.wordpress_role.replace('_', ' '),
          user.roles.panel_role.replace('_', ' ')
        ]);
      });

      return {
        ...state,
        users_data: users_data
      };
    case usersConstants.EXPORT_USERS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

export function userValidation(state = initialStateValidateUser, action) {
  switch (action.type) {
    case usersConstants.VALIDATE_USER_SUCCESS:
      return {
        ...state,
        validation_messages: action.payload
      };
    case usersConstants.VALIDATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
