import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Bar } from 'react-chartjs-2';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { store } from 'react-notifications-component';

import MonthRangePicker from 'pages/dashboard/containers/monthlyActiveUsers/components/date-pickers/month-range-picker';
import { mausActions } from 'pages/dashboard/containers/monthlyActiveUsers/actions/maus';
import { mausConstants } from 'pages/dashboard/containers/monthlyActiveUsers/constants';

const moment = require('moment');
const years = Array.from(new Array(10), (val, index) => index + 2019);

class MonthlyActiveUsers extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props, maus_report_request: false };
  }

  componentDidMount() {
    this.props.dispatch(mausActions.fetchMAUS(this.props));
  }

  handleFilterChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleDateChange = (date_state, date_value) => {
    this.setState({ [date_state]: date_value });
  };

  handleMonthRangeDataRefresh = (startDate, endDate) => {
    if (startDate === undefined || endDate === undefined) {
      store.addNotification(mausConstants.MAUS_DATE_ERROR);
      return;
    }
    this.setState({ maus_start_date: startDate, maus_end_date: endDate }, () => {
      this.props.dispatch(mausActions.fetchMAUS(this.state));
    });
  };

  handleMAUSRefresh = () => {
    this.props.dispatch(mausActions.fetchMAUS(this.state));
  };

  requestMAUSDetailedReport = () => {
    this.props.dispatch(mausActions.requestMAUSReport(this.state, true));
    this.setState({ maus_report_request: true });
  };

  render() {
    const { maus_data_labels, maus_data, maus_start_date, maus_end_date, maus_quarter, maus_year } = this.props;

    let labels = [
      'Report: ' + this.state.maus_filter_type.charAt(0).toUpperCase() + this.state.maus_filter_type.slice(1)
    ];
    if (this.state.maus_filter_type === 'yearly') {
      labels.push('Year: ' + maus_year);
    } else if (this.state.maus_filter_type === 'quarterly') {
      labels.push('Quarter: Q' + maus_quarter);
    } else if (this.state.maus_filter_type === 'monthly') {
      labels.push('Start Date: ' + moment(maus_start_date).format('M-YYYY'));
      labels.push('End Date: ' + moment(maus_end_date).format('M-YYYY'));
    }

    const csvData = [labels, ['Month'].concat(maus_data_labels), ['MAUs'].concat(maus_data)];
    const data = {
      labels: maus_data_labels,
      datasets: [
        {
          label: 'Monthly Active Users',
          fill: false,
          data: maus_data,
          backgroundColor: '#95969a',
          hoverBackgroundColor: '#dd1f25'
        }
      ]
    };

    return (
      <div>
        <div>
          <h2>Monthly Active Users</h2>
        </div>
        <div>
          <div className="filter-bar">
            <div className="filter-label">
              <span className="filter-text">Filters</span>
              <div controlid="maus_filter_type">
                <select
                  className="form-control small"
                  id="maus_filter_type"
                  name="maus_filter_type"
                  aria-label="Select Time Type"
                  value={this.state.maus_filter_type ? this.state.maus_filter_type : ''}
                  onChange={this.handleFilterChange}
                >
                  <option value="">Select</option>
                  <option value="monthly">Monthly</option>
                  <option value="quarterly">Quarterly</option>
                  <option value="yearly">Yearly</option>
                </select>
              </div>
            </div>

            <div className="filter-month">
              <div className={this.state.maus_filter_type === 'monthly' ? '' : 'hidden'}>
                <MonthRangePicker
                  handleMonthRangeDataRefresh={this.handleMonthRangeDataRefresh}
                  handleDateChange={this.handleDateChange}
                />
              </div>
              <div
                className={`filter-year ${
                  this.state.maus_filter_type === 'yearly' || this.state.maus_filter_type === 'quarterly'
                    ? ''
                    : 'hidden'
                }
                `}
              >
                <select
                  className="form-control small"
                  id="maus_year"
                  name="maus_year"
                  aria-label="Select Year"
                  value={this.state.maus_year ? this.state.maus_year : ''}
                  onChange={this.handleFilterChange}
                >
                  <option value="">Select Year</option>
                  {years.map((year, index) => {
                    return (
                      <option key={`year${index}`} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className={`filter-quarter ${this.state.maus_filter_type === 'quarterly' ? '' : 'hidden'}`}>
                <select
                  className="form-control small"
                  id="maus_quarter"
                  name="maus_quarter"
                  aria-label="Select Quarter"
                  value={this.state.maus_quarter ? this.state.maus_quarter : ''}
                  onChange={this.handleFilterChange}
                >
                  <option value="">Select Quarter</option>
                  <option value="1">Q1</option>
                  <option value="2">Q2</option>
                  <option value="3">Q3</option>
                  <option value="4">Q4</option>
                </select>
              </div>

              <button
                className={`refresh-link btn btn-link ${
                  this.state.maus_filter_type === 'yearly' || this.state.maus_filter_type === 'quarterly'
                    ? ''
                    : 'hidden'
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleMAUSRefresh();
                }}
              >
                View <FontAwesomeIcon icon={Icons.faSyncAlt} />
              </button>
            </div>
          </div>

          <div className="filter-head">
            <ul className="inline-list">
              <li>
                <CSVLink className="btn btn-link" filename="maus_report.csv" data={csvData}>
                  <FontAwesomeIcon icon={Icons.faDownload} />
                  <strong className="text">
                    <span className="hidden-mobile">Download</span> MAUs
                  </strong>
                </CSVLink>
              </li>
              <li>
                <button className="btn btn-link" onClick={this.requestMAUSDetailedReport}>
                  <FontAwesomeIcon icon={Icons.faDownload} />
                  <strong className="text">
                    <span className="hidden-mobile">Download</span> User Report
                  </strong>
                </button>
              </li>
              <li className="hidden">
                <CSVLink
                  filename="user_report.csv"
                  className="btn btn-link btn-download-csv"
                  onClick={() => {
                    this.setState({ maus_report_request: false });
                  }}
                  data={this.props.maus_detailed_report}
                >
                  <FontAwesomeIcon icon={Icons.faDownload} />
                  <strong className="text">
                    <span className="hidden-mobile">User Report Data</span>
                  </strong>
                </CSVLink>
              </li>
            </ul>
          </div>
          <div className="chart-frame">
            <Bar
              ref="chart"
              data={data}
              width={600}
              height={300}
              options={{
                maintainAspectRatio: false,
                legend: {
                  display: false
                },
                scales: {
                  xAxes: [
                    {
                      gridLines: {
                        display: false
                      },
                      ticks: {
                        beginAtZero: true,
                        fontFamily: '"Lato", sans-serif',
                        fontSize: 11,
                        fontStyle: 700
                      }
                    }
                  ],
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        userCallback: function(label, index, labels) {
                          if (Math.floor(label) === label) {
                            return label;
                          }
                        },
                        fontFamily: '"Lato", sans-serif',
                        fontSize: 11,
                        fontStyle: 700
                      }
                    }
                  ]
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    maus_data_labels,
    maus_data,
    maus_filter_type,
    maus_start_date,
    maus_end_date,
    maus_quarter,
    maus_year,
    maus_detailed_report
  } = state.MAUS;
  return {
    maus_data_labels,
    maus_data,
    maus_filter_type,
    maus_start_date,
    maus_end_date,
    maus_quarter,
    maus_year,
    maus_detailed_report
  };
}

const connectedMAUS = connect(mapStateToProps)(MonthlyActiveUsers);
export { connectedMAUS as MonthlyActiveUsers };
