import React from 'react';
import { Modal } from 'react-bootstrap';

const VideoPopup = (props) => {
  return (
    <div>
      <Modal onHide={props.onHide} show={props.show} size={props.size} className="video-popup">
        <div>
          <iframe
            width="800"
            height="600"
            src="https://www.youtube.com/embed/uurN8o_wg8w?autoplay=1"
            frameBorder="0"
            title="Feel stuck? Watch our getting-started video."
            allow="accelerometer; autoplay; encrypted-media; gyroscope"
            allowFullScreen
          />
        </div>
      </Modal>
    </div>
  );
};

export default VideoPopup;
