import { EDX_API_ENDPOINTS } from 'config';
import { getPanelLmsBaseUrl } from 'helpers';

const axios = require('axios');

export const quickStatsService = {
  fetchEnrolledCoursesQuickStats,
  fetchCourseCompletionsQuickStats
};

function fetchEnrolledCoursesQuickStats(params) {
  const url = getPanelLmsBaseUrl() + EDX_API_ENDPOINTS.FETCH_MONTHLY_ENROLLMENT;
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    params: params,
    url
  };
  return axios(requestOptions);
}

function fetchCourseCompletionsQuickStats(params) {
  const url = getPanelLmsBaseUrl() + EDX_API_ENDPOINTS.FETCH_MONTHLY_COURSE_COMPLETIONS;
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    params: params,
    url
  };
  return axios(requestOptions);
}
