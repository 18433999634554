import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { getWordpressBaseUrl } from 'helpers';

import { usersActions } from 'pages/users/containers/usersContainer/actions';

const AddUser = (props) => {
  const { validation_messages } = props;
  const yearlyDropdownOptions = Array.from(new Array(new Date().getFullYear() - 1900), (val, index) => index + 1900);
  const [inputs, setInputs] = useState({
    username: '',
    name: '',
    year_of_birth: '',
    email: '',
    is_staff: true,
    is_active: true,
    is_superuser: false,
    lms_role: 'learner',
    wordpress_role: 'subscriber',
    panel_role: 'panel_restricted'
  });

  const resetInputs = () => {
    setInputs({
      username: '',
      name: '',
      year_of_birth: '',
      email: '',
      is_staff: true,
      is_active: true,
      is_superuser: false,
      lms_role: 'learner',
      wordpress_role: 'subscriber',
      panel_role: 'panel_restricted'
    });
    props.searchreset();
  };

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name === 'is_active') {
      setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.checked }));
    } else {
      setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
    }
  };

  const handleValidation = (event) => {
    event.persist();
    props.dispatch(usersActions.validateUser(inputs));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(usersActions.validateUser(inputs, usersActions.addNewUser(inputs, props.onHide, resetInputs)));
  };

  return (
    <div>
      <Modal onHide={props.onHide} show={props.show} size={props.size}>
        <Modal.Header>
          <Modal.Title>
            <span className="close" onClick={props.onHide}>
              <FontAwesomeIcon icon={Icons.faTimes} />
            </span>
            <h2>Add User</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="email">
              Email<span className="required">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              onChange={handleInputChange}
              onBlur={handleValidation}
              value={inputs.email}
              required
            />
            <span className="text-error">{validation_messages['email']}</span>
          </div>
          <div className="form-group">
            <label htmlFor="username">
              Username
              <span className="required">*</span>
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="form-control"
              onChange={handleInputChange}
              onBlur={handleValidation}
              value={inputs.username}
              required
            />
            <span className="text-error">{validation_messages['username']}</span>
          </div>
          <div className="form-group">
            <label htmlFor="name">
              Full Name<span className="required">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              onChange={handleInputChange}
              onBlur={handleValidation}
              value={inputs.name}
              required
            />
            <span className="text-error">{validation_messages['name']}</span>
          </div>

          <div className="form-group">
            <label htmlFor="year_of_birth">Year of Birth</label>
            <select
              className="form-control"
              id="year_of_birth"
              name="year_of_birth"
              value={inputs.year_of_birth ? inputs.year_of_birth : ''}
              onChange={handleInputChange}
            >
              <option value=""> </option>
              {yearlyDropdownOptions.map((year, index) => {
                return (
                  <option key={`year${index}`} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                name="is_active"
                id="is_active"
                onChange={handleInputChange}
                value={inputs.is_active}
                defaultChecked={inputs.is_active}
              />
              <label className="custom-control-label" htmlFor="is_active">
                Active
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="admin">Edly Panel Role</label>
            <div className="roles-row">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="panel_role"
                  className="custom-control-input"
                  id="panel_restricted_filter"
                  onClick={handleInputChange}
                  check={(inputs.panel_role.toString() === 'panel_restricted').toString()}
                  value="panel_restricted"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="panel_restricted_filter">
                  Restricted
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="panel_role"
                  className="custom-control-input"
                  id="panel_user_filter"
                  onClick={handleInputChange}
                  check={(inputs.panel_role.toString() === 'panel_user').toString()}
                  value="panel_user"
                />
                <label className="custom-control-label" htmlFor="panel_user_filter">
                  Panel User
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="panel_role"
                  className="custom-control-input"
                  id="panel_admin_filter"
                  onClick={handleInputChange}
                  check={(inputs.panel_role.toString() === 'panel_admin').toString()}
                  value="panel_admin"
                />
                <label className="custom-control-label" htmlFor="panel_admin_filter">
                  Panel Admin
                </label>
              </div>
            </div>
          </div>
          {getWordpressBaseUrl() && (
            <div className="form-group">
              <label htmlFor="admin">Discovery Role</label>
              <div className="roles-row">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    name="wordpress_role"
                    className="custom-control-input"
                    id="subscriber_filter"
                    onClick={handleInputChange}
                    check={(inputs.wordpress_role.toString() === 'subscriber').toString()}
                    value="subscriber"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="subscriber_filter">
                    Subscriber
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    name="wordpress_role"
                    className="custom-control-input"
                    id="edly_admin_filter"
                    onClick={handleInputChange}
                    check={(inputs.wordpress_role.toString() === 'edly_admin').toString()}
                    value="edly_admin"
                  />
                  <label className="custom-control-label" htmlFor="edly_admin_filter">
                    Edly Admin
                  </label>
                </div>
              </div>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="admin">LMS Role</label>
            <div className="roles-row">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="lms_role"
                  className="custom-control-input"
                  id="learner_filter"
                  onClick={handleInputChange}
                  check={(inputs.lms_role.toString() === 'learner').toString()}
                  value="learner"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="learner_filter">
                  Learner
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="lms_role"
                  className="custom-control-input"
                  id="staff_filter"
                  onClick={handleInputChange}
                  check={(inputs.lms_role.toString() === 'staff').toString()}
                  value="staff"
                />
                <label className="custom-control-label" htmlFor="staff_filter">
                  Staff
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="lg" onClick={props.onHide}>
            Cancel
          </Button>
          <Button variant="primary" size="lg" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddUser;
