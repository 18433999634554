import { messageNotification, validateLoginSession } from 'helpers';
import { quickStatsConstants } from 'pages/dashboard/containers/quickStats/constants';
import { quickStatsService, mausService } from 'services';

const moment = require('moment');

export const quickStatsActions = {
  fetchMAUSQuickStats,
  fetchEnrolledCoursesQuickStats,
  fetchCourseCompletionsQuickStats
};

function fetchMAUSQuickStats() {
  return (dispatch) => {
    let params = {
      type: 'monthly',
      start_date: moment()
        .subtract(1, 'months')
        .endOf('month')
        .format('M-YYYY'), // Previous month date
      end_date: moment().format('M-YYYY') // Current month date
    };
    mausService
      .fetchMAUS(params)
      .then((userData) => {
        Promise.resolve(
          dispatch({
            type: quickStatsConstants.FETCH_MAUS_SUCCESS,
            payload: userData.data
          })
        );
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);

        if (error.response.status !== 403) {
          let error_message = 'There was an error requesting MAUs Report!';
          if (error.response.data.error) {
            error_message = error.response.data.error;
          }
          messageNotification('danger', 'There was an error fetching MAUs', error_message);
        }
      });
  };
}

function fetchEnrolledCoursesQuickStats() {
  return (dispatch) => {
    let params = {
      type: 'monthly',
      start_date: moment()
        .subtract(1, 'months')
        .endOf('month')
        .format('M-YYYY'), // Previous month date
      end_date: moment().format('M-YYYY') // Current month date
    };

    quickStatsService
      .fetchEnrolledCoursesQuickStats(params)
      .then((coursesData) => {
        Promise.resolve(
          dispatch({
            type: quickStatsConstants.FETCH_ENROLLED_COURSES_SUCCESS,
            payload: coursesData.data
          })
        );
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        if (error.response.status !== 403) {
          let error_message = 'There was an error requesting enrolled courses report!';
          if (error.response.data.error) {
            error_message = error.response.data.error;
          }
          messageNotification('danger', 'There was an error fetching report', error_message);
        }
      });
  };
}

function fetchCourseCompletionsQuickStats() {
  return (dispatch) => {
    let params = {
      type: 'monthly',
      start_date: moment()
        .subtract(1, 'months')
        .endOf('month')
        .format('M-YYYY'), // Previous month date
      end_date: moment().format('M-YYYY') // Current month date
    };

    quickStatsService
      .fetchCourseCompletionsQuickStats(params)
      .then((courseCompletionsData) => {
        Promise.resolve(
          dispatch({
            type: quickStatsConstants.FETCH_COURSE_COMPLETIONS_SUCCESS,
            payload: courseCompletionsData.data
          })
        );
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        if (error.response.status !== 403) {
          let error_message = 'There was an error requesting course completions!';
          if (error.response.data.error) {
            error_message = error.response.data.error;
          }
          messageNotification('danger', 'There was an error fetching course completions', error_message);
        }
      });
  };
}
