import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'pages/themes/index.scss';

import ThemePreview from 'pages/themes/containers/themesContainer/components/themePreview';

const ThemesCenter = (props) => {
  const { themes, organization } = props;
  const [currentSiteTheme, setCurrentSiteTheme] = useState(organization.current_site_theme);
  const [activeTheme, setActiveTheme] = useState(organization.current_site_theme);

  const handleThemeChange = (e) => {
    setCurrentSiteTheme(e.currentTarget.dataset['value']);
  };

  const themeItems = themes.map((theme, index) => {
    return (
      <ThemePreview
        key={index}
        theme={theme}
        organization={organization}
        dispatch={props.dispatch}
        currentSiteTheme={currentSiteTheme}
        activeTheme={activeTheme}
        setActiveTheme={setActiveTheme}
      />
    );
  });

  const themes_names = themes.map((theme, index) => {
    return (
      <li
        key={index}
        className={currentSiteTheme === theme.theme_name ? 'active' : ''}
        data-value={theme.theme_name}
        onClick={handleThemeChange}
      >
        <span>
          {theme.theme_name} <em>{activeTheme === theme.theme_name ? '(active)' : ''}</em>
        </span>
      </li>
    );
  });

  return (
    <div>
      <div className="tabs">
        <ul className="tab-links">{themes_names}</ul>
        <div className="tab-content">{themeItems}</div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { themes } = state.THEMES;
  const { organization } = state.ORGANIZATION;

  return {
    themes,
    organization
  };
}

export default connect(mapStateToProps)(ThemesCenter);
