const themesConstants = {
  FETCH_THEMES_SUCCESS: 'THEMES_FETCH_SUCCESS',
  FETCH_THEMES_FAILURE: 'THEMES_FETCH_FAILURE'
};

const organizationsConstants = {
  FETCH_ORGANIZATION: 'FETCH_ORGANIZATION',
  FETCH_ORGANIZATIONS_FAILURE: 'FETCH_ORGANIZATIONS_FAILURE'
};

export { themesConstants, organizationsConstants };
