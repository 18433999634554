import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import { getWordpressBaseUrl } from 'helpers';

const UserListItem = (props) => {
  const {
    username,
    user,
    selectedUsers,
    handleSelectedUsers,
    handleUserStatusUpdate,
    setUser,
    setEditModalShow,
    handlePasswordReset
  } = props;

  return (
    <tr className={user.username === username ? 'disabled' : ''}>
      <td className="col-check">
        <div className={user.is_superuser ? 'custom-control custom-checkbox hidden' : 'custom-control custom-checkbox'}>
          <input
            type="checkbox"
            className="custom-control-input"
            id={user.id}
            name={user.username}
            value={user.username}
            checked={selectedUsers[user.username] === true}
            onChange={handleSelectedUsers}
          />
          <label className="custom-control-label" htmlFor={user.id}>
            <span className="sr-only">{user.username}</span>
          </label>
        </div>
      </td>
      <td data-title="Name " title={user.name}>
        <strong className="name">{user.name}&nbsp;</strong>
      </td>
      <td data-title="Username " title={user.username}>
        <span className="ellipsis username">{user.username}</span>
      </td>
      <td data-title="Email " title={user.email}>
        <span className="ellipsis email">{user.email}</span>
      </td>
      <td data-title="Status " className="status">
        <span
          title={user.is_active ? 'Deactivate User' : 'Activate User'}
          className={user.is_superuser || user.username === username ? 'hidden' : 'user-status'}
          onClick={() => handleUserStatusUpdate(user.username, user.is_active !== true, user.wordpress_role)}
        >
          <FontAwesomeIcon icon={user.is_active ? Icons.faCheckCircle : Icons.faBan} />
        </span>
        {user.is_superuser || user.username === username ? '--' : ''}
      </td>
      <td className="roles" data-title="LMS Role ">
        {user.lms_role === 'super_admin' ? 'Site Admin' : user.lms_role.replace('_', ' ')}
      </td>
      {getWordpressBaseUrl() && (
        <td className="roles" data-title="Discovery Admin Role ">
          {user.wordpress_role.replace('_', ' ')}
        </td>
      )}
      <td className="roles" data-title="Panel Role ">
        {user.panel_role.replace('panel_', '')}
      </td>
      <td>
        <div className={user.is_superuser || user.username === username ? 'actions hidden' : 'actions'}>
          <span
            onClick={() => {
              setUser(user);
              setEditModalShow(true);
            }}
            title="Edit User"
            className={user.is_superuser ? 'invisible' : ''}
          >
            <FontAwesomeIcon icon={Icons.faPencilAlt} />
          </span>
          <span
            title="Reset Password"
            className={user.is_superuser || user.username === username ? 'hidden' : ''}
            onClick={() => handlePasswordReset(user.email)}
          >
            <FontAwesomeIcon icon={Icons.faKey} />
          </span>
        </div>
      </td>
    </tr>
  );
};

export default UserListItem;
