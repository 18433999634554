import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { NavDropdown } from 'react-bootstrap';
import { notificationActions } from 'pages/notifications/containers/notifications/actions/notifications';

const NotificationDropdown = (props) => {
  const { notification_center, dispatch } = props;
  const unReadNotification = notification_center.filter((notification) => notification.is_read === false);

  const handleNotificationStatus = (notification_id, status) => {
    if (status === true) {
      return;
    }
    dispatch(notificationActions.updateNotificationStatus(notification_id, status !== true));
  };

  const messages = notification_center.slice(0, 5).map((notification, index) => {
    return (
      <button
        key={index}
        className={notification.is_read === true ? 'read dropdown-item' : 'unread dropdown-item'}
        title={notification.is_read === true ? 'Read' : 'Mark as read'}
        onClick={() => handleNotificationStatus(notification.id, notification.is_read)}
      >
        {notification.message}
      </button>
    );
  });

  return (
    <NavDropdown
      title={
        <span className="active">
          <FontAwesomeIcon icon={Icons.faBell} size="2x" />
          <span className="sr-only">Toggle notifications drop down</span>
          {unReadNotification.length > 0 ? <span className="badge">{unReadNotification.length}</span> : ''}
        </span>
      }
      id="notification-nav-dropdown"
      className="notifications"
      alignRight
    >
      <div className={messages.length > 0 ? 'notification-block' : 'notification-block hidden'}>{messages}</div>
      <div className={messages.length < 1 ? 'no-notification' : 'see-all-notification'}>
        <span className={messages.length < 1 ? '' : 'hidden'}>No Notification Available</span>
        <NavLink className={`btn btn-link ${notification_center.length > 0 ? '' : 'hidden'}`} to="/notifications">
          <span>See all +</span>
        </NavLink>
      </div>
    </NavDropdown>
  );
};

function mapStateToProps(state) {
  const { notification_center } = state.NOTIFICATION;
  return {
    notification_center
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ notificationActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDropdown);
