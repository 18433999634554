import React from 'react';

import Header from 'components/header';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Page401 = (props) => {
  if (props.panel_role !== 'panel_restricted') {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <div>
      <Header />
      <div className="error-page">
        <div className="error-block">
          <img src="https://edly-edx-theme-files.s3.amazonaws.com/error-page-assets/error-401.svg" alt="Error 401" />
          <h1>Access Denied!</h1>
          <h3 className="copy">
            Sorry, you do not have permission to access this page.
            <br />
            Please contact your system administrator.
          </h3>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { roles } = state.USER_ROLES;
  let panel_role = roles['panel_role'];
  return { panel_role };
}

export default connect(
  mapStateToProps,
  null
)(Page401);
