import { messageNotification, validateLoginSession, validatePanelAccess, getEcommerceBaseUrl } from 'helpers';
import { settingConstants } from 'pages/settings/containers/settingsContainer/constants/index';
import { userSettingsService } from 'services';

const updateSettings = (payload) => {
  return (dispatch) => {
    messageNotification('info', 'Accounts Settings', 'Account settings update in progress', 1000);
    userSettingsService
      .updateSettings(payload)
      .then((userData) => {
        if (userData.data['profile_image']) {
          userData.data['profile_image'] = userData.data['profile_image']['image_url_full'];
        }
        dispatch({
          type: settingConstants.UPDATE_SETTINGS,
          payload: userData.data
        });
        messageNotification('success', 'Accounts Settings', 'Account Settings Updated Successfully');
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        if (error.response.data.field_errors) {
          let error_messages = error.response.data.field_errors;
          const messages = Object.keys(error_messages).map((key) => {
            return key.toUpperCase() + ':' + error_messages[key].user_message;
          });
          messageNotification('danger', 'Accounts Settings Update Failed', messages.join());
        } else if (error.response.data.detail) {
          messageNotification('danger', 'Accounts Settings Update Failed', error.response.data.detail);
        }
      });
  };
};

const getSettings = () => {
  return (dispatch) => {
    userSettingsService
      .getSettings()
      .then((userData) => {
        userData.data[0]['profile_image'] = userData.data[0]['profile_image']['image_url_full'];
        userData.data[0]['old_email'] = userData.data[0]['email'];
        Promise.resolve(
          dispatch({
            type: settingConstants.FETCH_SETTINGS,
            payload: userData.data[0]
          })
        );
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
      });
  };
};

const getUserPreferences = () => {
  return (dispatch) => {
    userSettingsService
      .getUserPreferences()
      .then((userData) => {
        Promise.resolve(
          dispatch({
            type: settingConstants.FETCH_USER_PREFERENCES,
            payload: userData.data
          })
        );
        dispatch(getCountryTimezones(userData.data.country));
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
      });
  };
};

const updateUserPreferences = (payload) => {
  return (dispatch) => {
    messageNotification('info', 'User Preferences', 'User preferences update in progress', 1000);
    userSettingsService
      .updateUserPreferences(payload)
      .then((userData) => {
        Promise.resolve(
          dispatch({
            type: settingConstants.UPDATE_USER_PREFERENCES,
            payload: userData.data
          })
        );
        messageNotification('success', 'User Preferences', 'User Preferences Updated Successfully');
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        if (error.response.data.field_errors) {
          let error_messages = error.response.data.field_errors;
          const messages = Object.keys(error_messages).map((key) => {
            return key.toUpperCase() + ':' + error_messages[key].user_message;
          });
          messageNotification('danger', 'User Preferences Update Failed', messages.join());
        } else if (error.response.data.detail) {
          messageNotification('danger', 'User Preferences Update Failed', error.response.data.detail);
        }
      });
  };
};

const resetUserPassword = (payload) => {
  return (dispatch) => {
    userSettingsService
      .resetUserPassword(payload)
      .then((userData) => {
        Promise.resolve(
          dispatch({
            type: settingConstants.RESET_USER_PASSWORD,
            payload: userData.data
          })
        );
        messageNotification(
          'success',
          'Reset Password',
          'We have e-mailed you instructions for setting your password to the e-mail address you submitted.You should be receiving it shortly.'
        );
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
      });
  };
};

const updateUserProfileImage = (payload, profile_image) => {
  return (dispatch) => {
    userSettingsService
      .updateUserProfileImage(payload, profile_image)
      .then((userData) => {
        Promise.resolve(
          dispatch({
            type: settingConstants.UPDATE_USER_PROFILE_IMAGE,
            payload: userData.data
          })
        );
        messageNotification('success', 'Account Settings', 'Profile Image Updated Successfully.');
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'Profile Image Updated Failed', error.response.data.user_message);
      });
  };
};

const getCountryTimezones = (country_code) => {
  return (dispatch) => {
    userSettingsService
      .getCountryTimezones(country_code)
      .then((userData) => {
        Promise.resolve(
          dispatch({
            type: settingConstants.FETCH_COUNTRY_TIMEZONES,
            payload: userData.data
          })
        );
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
      });
  };
};

const getPanelUserRoles = () => {
  return (dispatch) => {
    userSettingsService
      .getPanelUserRoles()
      .then((userData) => {
        if (getEcommerceBaseUrl()) {
          dispatch(getEcommerceLoginSession());
        }
        Promise.resolve(
          dispatch({ type: settingConstants.FETCH_USER_ROLES, payload: userData.data[0] }),
          dispatch({ type: settingConstants.APPLICATION_LOADED })
        );
        let panel_role = 'panel_restricted';
        if (userData.data.length > 0) {
          panel_role = userData.data[0]['roles']['panel_role'];
        }
        validatePanelAccess(panel_role);
      })
      .catch((error) => {
        dispatch(SessionLogOut());
        dispatch({ type: settingConstants.FETCH_USER_ROLES_FAILURE, payload: error });
        validateLoginSession(dispatch, error);
      });
  };
};

const getLmsUserSessionInfo = () => {
  return (dispatch) => {
    userSettingsService
      .getLmsUserSessionInfo()
      .then((sessionData) => {
        localStorage.setItem('lms_csrf_token', sessionData.data.csrf_token);
      })
      .catch((error) => {
        localStorage.removeItem('lms_csrf_token');
      });
  };
};

const getEcommerceUserSessionInfo = () => {
  return (dispatch) => {
    userSettingsService
      .getEcommerceUserSessionInfo()
      .then((sessionData) => {
        localStorage.setItem('ecommerce_csrf_token', sessionData.data.csrf_token);
      })
      .catch((error) => {
        localStorage.removeItem('ecommerce_csrf_token');
      });
  };
};

const getPanelUserSessionInfo = () => {
  return (dispatch) => {
    userSettingsService
      .getPanelUserSessionInfo()
      .then((sessionData) => {
        localStorage.setItem('panel_csrf_token', sessionData.data.csrf_token);
      })
      .catch((error) => {
        localStorage.removeItem('panel_csrf_token');
      });
  };
};

const getEcommerceLoginSession = () => {
  return (dispatch) => {
    userSettingsService
      .getEcommerceLoginSession()
      .then((response) => {
        dispatch(getEcommerceUserSessionInfo());
        Promise.resolve(dispatch({ type: settingConstants.FETCH_Ecommerce_Login_Session, payload: response }));
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
      });
  };
};

const SessionLogOut = () => {
  return (dispatch) => {
    userSettingsService.SessionLogOut().then((response) => {
      dispatch({ type: settingConstants.APPLICATION_LOADED });
      localStorage.removeItem('edx_username');
      localStorage.removeItem('lms_csrf_token');
      localStorage.removeItem('panel_csrf_token');
      localStorage.removeItem('wordpress_base_url');
      localStorage.removeItem('ecommerce_base_url');
    });
  };
};

export const settingsAction = {
  getUserPreferences,
  resetUserPassword,
  updateUserProfileImage,
  updateUserPreferences,
  updateSettings,
  getSettings,
  SessionLogOut,
  getCountryTimezones,
  getLmsUserSessionInfo,
  getEcommerceUserSessionInfo,
  getPanelUserSessionInfo,
  getPanelUserRoles,
  getEcommerceLoginSession
};
