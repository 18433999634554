import React, { Fragment } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const NotificationModal = (props) => {
  return (
    <Fragment>
      <Modal
        onHide={props.onHide}
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="close" onClick={props.onHide}>
              <FontAwesomeIcon icon={Icons.faTimes} />
            </span>
            <strong className="title">Attention</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            To view Edly Insights, you need to sign in with your Google account attached with your Edly Panel account.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              name="hide_notification"
              id="hide_notification"
              onChange={props.handleCheckboxChange}
              checked={props.checkboxValue}
            />
            <label className="custom-control-label" htmlFor="hide_notification">
              Do not show this message again.
            </label>
          </div>
          <Button onClick={props.onClose}>Okay</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default NotificationModal;
