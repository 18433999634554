import { quickStatsConstants } from 'pages/dashboard/containers/quickStats/constants';

const initialState = {
  monthly_active_quick_stats_data_label: [],
  monthly_active_quick_stats_data: [0, 0],
  enrolled_courses_quick_stats_data_label: [],
  enrolled_courses_quick_stats_data: [0, 0],
  course_completions_quick_stats_data_label: [],
  course_completions_quick_stats_data: [0, 0]
};

export function fetchQuickStats(state = initialState, action) {
  switch (action.type) {
    case quickStatsConstants.FETCH_MAUS_SUCCESS:
      return {
        ...state,
        monthly_active_quick_stats_data_label: action.payload.data.month_names,
        monthly_active_quick_stats_data: action.payload.data.monthly_users_count
      };
    case quickStatsConstants.FETCH_ENROLLED_COURSES_SUCCESS:
      return {
        ...state,
        enrolled_courses_quick_stats_data_label: action.payload.data.month_names,
        enrolled_courses_quick_stats_data: action.payload.data.monthly_enrollments_count
      };
    case quickStatsConstants.FETCH_COURSE_COMPLETIONS_SUCCESS:
      return {
        ...state,
        course_completions_quick_stats_data_label: action.payload.data.month_names,
        course_completions_quick_stats_data: action.payload.data.monthly_course_completions_count
      };
    default:
      return state;
  }
}
