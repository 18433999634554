import Cookies from 'js-cookie';

import { notificationsConstants } from 'pages/notifications/containers/notifications/constants';
import { notificationService } from 'services';
import { messageNotification, setServicesNotificationsCookiesExpiry } from 'helpers';
import { SERVICES_NOTIFICATIONS_DOMAIN } from 'config';

export const notificationActions = {
  fetchNotifications,
  fetchServicesNotifications,
  updateServicesNotificationsStatus,
  updateNotificationStatus
};

function fetchNotifications(page_number) {
  return (dispatch) => {
    notificationService
      .fetchNotifications(page_number)
      .then((notifications) => {
        Promise.resolve(
          dispatch({
            type: notificationsConstants.FETCH_NOTIFICATIONS,
            payload: notifications.data,
            currentPage: page_number
          })
        );
      })
      .catch((error) => {
        if (error.response) {
          messageNotification('danger', 'There was an error fetching system notifications', error.response);
        }
      });
  };
}

function fetchServicesNotifications(page_number) {
  return (dispatch) => {
    notificationService
      .fetchServicesNotifications(page_number)
      .then((notifications) => {
        Promise.resolve(
          dispatch({ type: notificationsConstants.FETCH_SERVICES_NOTIFICATIONS_SUCCESS, payload: notifications.data })
        );
      })
      .catch((error) => {
        dispatch({ type: notificationsConstants.FETCH_SERVICES_NOTIFICATIONS_FAILURE, payload: error });
        if (error.response) {
          messageNotification('danger', 'There was an error fetching services notifications', error.response);
        }
      });
  };
}

function updateNotificationStatus(notification_id, status, page_number) {
  return (dispatch) => {
    messageNotification('info', 'Notification Status', 'Notification status update in progress', 1000);
    notificationService
      .updateNotificationStatus(notification_id, status)
      .then((notifcationData) => {
        Promise.resolve(
          dispatch({
            type: notificationsConstants.UPDATE_NOTIFICATION_STATUS,
            payload: notifcationData.data
          }),
          dispatch(fetchNotifications(page_number))
        );
        let message = status === false ? 'Notication Unread' : 'Notification Read';
        messageNotification('success', 'Notification Status Update', message);
      })
      .catch((error) => {
        if (error.response) {
          messageNotification('danger', 'There was an error updating notification status', error.response.data.detail);
        }
      });
  };
}

function updateServicesNotificationsStatus(notification_id, status) {
  return (dispatch) => {
    let display_status = Cookies.getJSON('panel_services_notifications_status');
    display_status[notification_id.toString()] = status;
    if (
      Cookies.get('panel_services_notifications_status') === undefined ||
      Cookies.get('panel_services_notifications_daily_status') === undefined
    ) {
      setServicesNotificationsCookiesExpiry(display_status);
    }
    Cookies.set('panel_services_notifications_status', display_status, {
      domain: SERVICES_NOTIFICATIONS_DOMAIN,
      path: '/'
    });

    dispatch({
      type: notificationsConstants.UPDATE_SERVICES_NOTIFICATIONS_DISPLAY_STATUS,
      payload: { notification_id: notification_id, display_status: display_status }
    });
  };
}
