import $ from 'jquery';
import { store } from 'react-notifications-component';
import Cookies from 'js-cookie';

import { HTTP_PROTOCOL, PANEL_BACKEND_BASE_DOMAIN, SERVICES_NOTIFICATIONS_DOMAIN } from 'config';
import { history } from 'helpers/history';
import { settingConstants } from 'pages/settings/containers/settingsContainer/constants/index';
import { notificationActions } from 'pages/notifications/containers/notifications/actions/notifications';
import { settingsAction } from 'pages/settings/containers/settingsContainer/actions';
import { usersActions } from 'pages/users/containers/usersContainer/actions';
import { themesActions } from 'pages/themes/containers/themesContainer/actions/themes';
import { quickStatsActions } from 'pages/dashboard/containers/quickStats/actions/quickStats';

export const configurePanelApp = (dispatch) => {
  dispatch(notificationActions.fetchServicesNotifications());
  dispatch(notificationActions.fetchNotifications());
  dispatch(settingsAction.getSettings());
  dispatch(usersActions.fetchUsers());
  dispatch(settingsAction.getLmsUserSessionInfo());
  dispatch(settingsAction.getPanelUserSessionInfo());
  dispatch(settingsAction.getUserPreferences());
  dispatch(themesActions.fetchThemes());
  dispatch(quickStatsActions.fetchMAUSQuickStats());
  dispatch(quickStatsActions.fetchEnrolledCoursesQuickStats());
  dispatch(quickStatsActions.fetchCourseCompletionsQuickStats());
};

export const getPanelBackendUrl = () => {
  let panelBackendUrl = null;
  let subDomain = $.trim(localStorage.getItem('subDomain'));
  if (subDomain) {
    panelBackendUrl = HTTP_PROTOCOL + subDomain + PANEL_BACKEND_BASE_DOMAIN + '/';
  }
  return panelBackendUrl;
};

export const getPanelLmsBaseUrl = () => {
  return $.trim(localStorage.getItem('panel_lms_base_url'));
};

export const getEcommerceBaseUrl = () => {
  return $.trim(localStorage.getItem('ecommerce_base_url'));
};

export const getWordpressBaseUrl = () => {
  return $.trim(localStorage.getItem('wordpress_base_url'));
};

export const getEdlyServiceUrl = (organization, service_key) => {
  let edlyServiceUrl = '/#';
  if (organization && organization.edly_services_urls && service_key in organization.edly_services_urls) {
    edlyServiceUrl = organization.edly_services_urls[service_key];
  }
  return edlyServiceUrl;
};

export const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = $.trim(cookies[i]);
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const setServicesNotificationsCookiesExpiry = (display_status) => {
  Cookies.set('panel_services_notifications_status', display_status, {
    domain: SERVICES_NOTIFICATIONS_DOMAIN,
    path: '/'
  });

  let midnight = new Date();
  midnight.setHours(23, 59, 59, 0);
  Cookies.set('panel_services_notifications_daily_status', true, {
    domain: SERVICES_NOTIFICATIONS_DOMAIN,
    expires: midnight,
    path: '/'
  });
};

export const messageNotification = (type, title, message, duration = 5000, onScreen = true) => {
  if (typeof message === 'string') {
    store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: duration,
        onScreen: onScreen
      }
    });
  }
};

export const validateLoginSession = (dispatch, error) => {
  if (!error.response) {
    return false;
  }
  let error_check = error.response.status === 403 || error.response.status === 401;
  let unauthorised = 'Authentication credentials were not provided.';

  if (error_check && unauthorised) {
    localStorage.removeItem('edx_username');
    localStorage.removeItem('subDomain');
    dispatch({ type: settingConstants.USER_SETTINGS_FAILURE, payload: error });
    return true;
  }
};

export const validatePanelAccess = (panel_role) => {
  if (panel_role === 'panel_restricted') {
    history.push('/401');
  }
};
