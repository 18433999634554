import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { NavDropdown, Dropdown } from 'react-bootstrap';

import Header from 'components/header';
import Footer from 'components/footer';
import SideBar from 'components/sidebar';
import NotificationsDropdown from 'pages/notifications/containers/notifications/components/notificationsDropdown';
import { getPanelBackendUrl } from 'helpers';

const ProtectedLayout = ({ children, ...props }) => {
  const { username, profile_image, roles } = props;
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem('edx_username');
    localStorage.removeItem('lms_csrf_token');
    localStorage.removeItem('panel_csrf_token');
    localStorage.removeItem('wordpress_base_url');
    localStorage.removeItem('ecommerce_base_url');
    window.location.href = e.currentTarget.getAttribute('href');
  };

  return (
    <Fragment>
      <a className="skip-link screen-reader-text" href="#content">
        Skip to content
      </a>
      <Header />
      <main
        className={
          window.location.pathname === '/see_all_apps' || window.location.pathname === '/notifications'
            ? 'main see-all-page'
            : 'main'
        }
        id="content"
      >
        <div className="container sub-header">
          <div className="client-logo">
            <img src={props.organization.current_site_logo} alt={props.organization.name} />
          </div>
          <div className="link-frame">
            <NotificationsDropdown />
            <NavDropdown
              className="user-block"
              id="userInfoOptions"
              title={
                <div className="info">
                  <div className="profile">
                    <img src={profile_image} alt="" height="35" width="35" />
                  </div>
                  <span className="name">{username}</span>
                </div>
              }
            >
              <div className="user-drop">
                <ul>
                  <li style={{ display: roles['panel_role'] === 'panel_admin' ? 'block' : 'none' }}>
                    <Dropdown.Item href="/settings">Edit profile</Dropdown.Item>
                  </li>
                  <li>
                    <a href={getPanelBackendUrl() + 'logout'} onClick={handleLogout}>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </NavDropdown>
          </div>
        </div>
        <div className="container">
          <div className="sidebar">
            <SideBar />
          </div>
          <div className="main-content">{children}</div>
        </div>
      </main>
      <Footer />
    </Fragment>
  );
};

function mapStateToProps(state) {
  const { username, profile_image } = state.USER_SETTINGS;
  const { organization } = state.ORGANIZATION;
  const { roles } = state.USER_ROLES;
  return {
    username,
    roles,
    profile_image,
    organization
  };
}

export default connect(mapStateToProps)(ProtectedLayout);
