import React from 'react';
import 'components/footer/index.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="link-box">
          <p className="copyrights">&copy; Edly {new Date().getFullYear()}. All rights reserved.</p>
        </div>
        <img
          className="accessibility-logo"
          height="32"
          width="75"
          src="https://www.w3.org/WAI/wcag2AA-blue-v.svg"
          alt="Level Double-A conformance, W3C WAI Web Content Accessibility Guidelines 2.0"
        />
      </div>
    </footer>
  );
};

export default Footer;
