import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const SideBarNav = (props) => {
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);

  return (
    <div>
      <div className={`side-nav ${mobileSidebarOpen ? 'open' : ''}`}>
        <span
          className="sidebar-toggle"
          onClick={() => setMobileSidebarOpen(!mobileSidebarOpen)}
          aria-label="Mobile sidebar toggler"
        >
          {' '}
        </span>
        <ul>
          <li>
            <NavLink to="/dashboard" activeClassName="active" onClick={() => setMobileSidebarOpen(false)}>
              <FontAwesomeIcon icon={Icons.faTachometerAlt} />
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li style={{ display: props.roles['panel_role'] === 'panel_admin' ? 'block' : 'none' }}>
            <NavLink to="/users" activeClassName="active" onClick={() => setMobileSidebarOpen(false)}>
              <FontAwesomeIcon icon={Icons.faUser} />
              <span>Users</span>
            </NavLink>
          </li>
          <li style={{ display: props.roles['panel_role'] === 'panel_admin' ? 'block' : 'none' }}>
            <NavLink to="/pricing" activeClassName="active" onClick={() => setMobileSidebarOpen(false)}>
              <FontAwesomeIcon icon={Icons.faMoneyCheckAlt} />
              <span>Plans</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/insights" activeClassName="active" onClick={() => setMobileSidebarOpen(false)}>
              <FontAwesomeIcon icon={Icons.faChartLine} />
              <span>Edly Insights</span>
            </NavLink>
          </li>
          <li style={{ display: props.roles['panel_role'] === 'panel_admin' ? 'block' : 'none' }}>
            <NavLink to="/themes" activeClassName="active" onClick={() => setMobileSidebarOpen(false)}>
              <FontAwesomeIcon icon={Icons.faPalette} />
              <span>Themes</span>
            </NavLink>
          </li>
          <li style={{ display: props.roles['panel_role'] === 'panel_admin' ? 'block' : 'none' }}>
            <NavLink to="/settings" activeClassName="active" onClick={() => setMobileSidebarOpen(false)}>
              <FontAwesomeIcon icon={Icons.faCog} />
              <span>Settings</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { name, username, email, profile_image } = state.USER_SETTINGS;
  const { roles } = state.USER_ROLES;
  return {
    name,
    username,
    email,
    profile_image,
    roles
  };
}

export default connect(mapStateToProps)(SideBarNav);
