import React, { useState } from 'react';
import { connect } from 'react-redux';

import UserListing from 'pages/users/containers/usersContainer/components/userListing/userListing';
import 'pages/users/containers/index.scss';
import 'assets/sass/components/pagination.scss';

const UsersCenter = (props) => {
  const [addModalShow, setAddModalShow] = useState(false);

  return (
    <div className="row">
      <div className="users-header">
        <h1>User Management</h1>
        <button
          className="btn btn-lg btn-primary"
          onClick={() => {
            setAddModalShow(true);
          }}
        >
          Add <span className="hidden-mobile">new user</span>
        </button>
      </div>
      <div className="tabs">
        <div className="tab">
          <div className="user-content">
            <UserListing addModalShow={addModalShow} setAddModalShow={setAddModalShow} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(UsersCenter);
