import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('edx_username') ? (
        <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
      ) : (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }
  />
);

export default PublicRoute;
