import { usersConstants } from 'pages/users/containers/usersContainer/constants';
import { usersService } from 'services';
import { messageNotification, validateLoginSession, getWordpressBaseUrl } from 'helpers';

const fetchUsers = (payload = {}) => {
  let { setPaginationLoading } = payload;
  return (dispatch) => {
    usersService
      .fetchUsers(payload)
      .then((users) => {
        Promise.resolve(
          dispatch({
            type: usersConstants.FETCH_SUCCESS,
            payload: users.data
          })
        );

        if (setPaginationLoading !== undefined) {
          setPaginationLoading(false);
        }
      })
      .catch((error) => {
        dispatch({
          type: usersConstants.FETCH_FAILURE,
          payload: error
        });
        if (error.response) {
          messageNotification('danger', 'There was an error fetching users', error.response);
        }
      });
  };
};

const addNewUser = (payload, closePopup, resetForm) => {
  return (dispatch) => {
    messageNotification('info', 'User Account Creation', 'User account creation in progress', 1000);
    usersService
      .addNewUser(payload)
      .then((users) => {
        Promise.resolve(
          dispatch({
            type: usersConstants.ADD_USER_SUCCESS,
            payload: users.data
          }),
          dispatch(fetchUsers())
        );
        if (getWordpressBaseUrl()) {
          dispatch(addWordpressUser(payload));
        }
        messageNotification('success', 'User Account Creation', 'New User Account Created Successfully');
        closePopup();
        resetForm();
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        dispatch({
          type: usersConstants.ADD_USER_FAILURE,
          payload: error
        });

        let error_message = [];
        Object.keys(error.response.data).forEach((key) => {
          error_message.push(`${key} : ${error.response.data[key]}`);
        });

        if (error_message.length > 0) {
          messageNotification('danger', 'There was an error creating new User', error_message.join());
        }
      });
  };
};

const updateUser = (payload, closePopup, searchreset) => {
  return (dispatch) => {
    messageNotification('info', 'User Account Update', 'User account update in progress', 1000);
    usersService
      .updateUser(payload)
      .then((users) => {
        Promise.resolve(
          dispatch({
            type: usersConstants.UPDATE_USER_SUCCESS,
            payload: users.data
          }),
          dispatch(fetchUsers())
        );
        if (getWordpressBaseUrl()) {
          dispatch(updateWordpressUser(payload));
        }
        messageNotification('success', 'User Account Update', 'User Account Updated Successfully');
        searchreset();
        closePopup();
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        dispatch({ type: usersConstants.UPDATE_USER_FAILURE, payload: error });

        let error_message = [];
        Object.keys(error.response.data).forEach((key) => {
          error_message.push(`${key} : ${error.response.data[key]}`);
        });

        if (error_message.length > 0) {
          messageNotification('danger', 'There was an error updating User', error_message.join());
        }
      });
  };
};

const resetUserPassword = (payload) => {
  return (dispatch) => {
    usersService
      .resetUserPassword(payload)
      .then((userData) => {
        Promise.resolve(
          dispatch({
            type: usersConstants.RESET_USER_PASSWORD,
            payload: userData.data
          })
        );
        messageNotification(
          'success',
          'Reset User Password',
          'We have e-mailed user, instructions for setting password to registered e-mail address.User should be receiving it shortly.'
        );
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
      });
  };
};

const updateUserStatus = (username, status, search, wordpress_role, activePageNumber = 1, roles) => {
  return (dispatch) => {
    usersService
      .updateUserStatus(username, status)
      .then((userData) => {
        Promise.resolve(
          dispatch({
            type: usersConstants.UPDATE_USER_STATUS,
            payload: userData.data
          }),
          dispatch(fetchUsers({ page_number: activePageNumber, searchText: search, roles }))
        );
        if (getWordpressBaseUrl()) {
          dispatch(updateWordpressUserStatus(username, status, wordpress_role));
        }
        messageNotification('success', 'User Account Status Update', 'User Account Status Updated Successfully');
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
      });
  };
};

const deactivateUsers = (selectedUsers, searchText, page_number = 1) => {
  return (dispatch) => {
    usersService
      .deactivateUsers(selectedUsers)
      .then((usersData) => {
        Promise.resolve(
          dispatch({
            type: usersConstants.DEACTIVATE_USERS_SUCCESS,
            payload: usersData.data
          }),
          dispatch(fetchUsers({ page_number, searchText }))
        );
        if (getWordpressBaseUrl()) {
          dispatch(deactivateWPUsers(selectedUsers));
        }
        messageNotification('success', 'Users Accounts Status Update', 'Users Accounts Deactivated Successfully');
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        let error_message = [];
        Object.keys(error.response.data).forEach((key) => {
          error_message.push(`${key} : ${error.response.data[key]}`);
        });

        if (error_message.length > 0) {
          messageNotification('danger', 'There was an error updating User', error_message.join());
        }
      });
  };
};

const deactivateWPUsers = (selectedUsers) => {
  return (dispatch) => {
    usersService
      .deactivateWPUsers(selectedUsers)
      .then((usersData) => {
        Promise.resolve(
          dispatch({
            type: usersConstants.DEACTIVATE_WP_USERS_SUCCESS,
            payload: usersData.data
          })
        );
      })
      .catch((error) => {
        if (error.response.data.message) {
          messageNotification('danger', 'There was an error in updating WordPress Users', error.response.data.message);
        }
        dispatch({
          type: usersConstants.DEACTIVATE_WP_USERS_FAILURE,
          payload: error
        });
      });
  };
};

const exportUsersInfo = (payload) => {
  return (dispatch) => {
    usersService
      .exportUsersInfo(payload)
      .then((usersData) => {
        Promise.resolve(
          dispatch({
            type: usersConstants.EXPORT_USERS_SUCCESS,
            payload: usersData
          })
        );
        document.querySelector('.btn-download-csv').click();
        messageNotification('success', 'Users Accounts Data Export', 'Users Accounts Data Exported Successfully');
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
      });
  };
};

const validateUser = (user_data, callback) => {
  return (dispatch) => {
    usersService
      .validateUser(user_data)
      .then((response) => {
        let validations = response.data.validation_decisions;
        let validation_messages = {};
        Object.keys(validations).forEach((key) => {
          if (validations[key] !== '') {
            validation_messages[`${key}`] = validations[key];
          }
        });

        if (Object.keys(validation_messages).length === 0 && callback !== undefined) {
          dispatch(callback);
        }

        Promise.resolve(
          dispatch({
            type: usersConstants.VALIDATE_USER_SUCCESS,
            payload: validation_messages
          })
        );
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        dispatch({ type: usersConstants.VALIDATE_USER_FAILURE, payload: error });
      });
  };
};

const addWordpressUser = (user) => {
  return (dispatch) => {
    usersService
      .addWordpressUser(user)
      .then((users) => {
        Promise.resolve(
          dispatch({
            type: usersConstants.ADD_WP_USER_SUCCESS,
            payload: users.data
          })
        );
      })
      .catch((error) => {
        if (error.response) {
          messageNotification(
            'danger',
            'There was an error in creating new WordPress User',
            error.response.data.message
          );
        }
        dispatch({
          type: usersConstants.ADD_WP_USER_FAILURE,
          payload: error
        });
      });
  };
};

const updateWordpressUser = (user) => {
  return (dispatch) => {
    usersService
      .updateWordpressUser(user)
      .then((users) => {
        Promise.resolve(
          dispatch({
            type: usersConstants.UPDATE_WP_USER_SUCCESS,
            payload: users.data
          })
        );
      })
      .catch((error) => {
        if (error.response.data.message) {
          messageNotification('danger', 'There was an error creating new WordPress User', error.response.data.message);
        }
        dispatch({
          type: usersConstants.UPDATE_WP_USER_FAILURE,
          payload: error
        });
      });
  };
};

const updateWordpressUserStatus = (username, status, wordpress_role) => {
  return (dispatch) => {
    usersService
      .updateWordpressUserStatus(username, status, wordpress_role)
      .then((userData) => {
        Promise.resolve(
          dispatch({
            type: usersConstants.UPDATE_WP_USER_STATUS_SUCCESS,
            payload: userData.data
          })
        );
      })
      .catch((error) => {
        dispatch({
          type: usersConstants.UPDATE_WP_USER_STATUS_FAILURE,
          payload: error
        });
        if (error.response.data.message) {
          messageNotification('danger', 'There was an error in updating WordPress User', error.response.data.message);
        }
      });
  };
};

export const usersActions = {
  fetchUsers,
  addNewUser,
  validateUser,
  updateUser,
  updateUserStatus,
  resetUserPassword,
  deactivateUsers,
  exportUsersInfo
};
