import React, { useState, Fragment } from 'react';

import VideoPopup from 'components/video/videopopup';
import VideoPlaceholder from 'assets/images/demo-video.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const Video = (props) => {
  const [videoModalShow, setVideoModalShow] = useState(false);
  return (
    <Fragment>
      <div>
        <div className="get-started-block">
          <div
            className="video-frame"
            onClick={() => {
              setVideoModalShow(true);
            }}
          >
            <img src={VideoPlaceholder} alt="Video Placeholder" />
          </div>
          <div className="description">
            <h2>Feel stuck? Watch our getting-started video.</h2>
            <button
              className="btn btn-arrow"
              onClick={() => {
                setVideoModalShow(true);
              }}
            >
              Watch now <FontAwesomeIcon icon={Icons.faArrowRight} />
            </button>
          </div>
        </div>
      </div>
      <VideoPopup
        show={videoModalShow}
        onHide={() => {
          setVideoModalShow(false);
        }}
        size="xl"
      />
    </Fragment>
  );
};

export default Video;
