export const mausConstants = {
  REQUEST_REPORT: 'MAUS_REQUEST_REPORT',
  FETCH_SUCCESS: 'MAUS_FETCH_SUCCESS',
  FETCH_FAILURE: 'MAUS_FETCH_FAILURE',
  MAUS_DATE_ERROR: {
    title: 'There was an error fetching MAUs',
    message: 'Please select both start and end date.',
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  }
};
