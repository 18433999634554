import React, { Component } from 'react';
import { Router, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Routes from 'components/routes/Routes';
import ErrorBoundary from 'components/error-boundary';
import FullPageLoader from 'components/loaders/FullPage';
import { history, configurePanelApp } from 'helpers';
import { FETCH_SERVICES_NOTIFICATION_TIMER } from 'config';
import { notificationActions } from 'pages/notifications/containers/notifications/actions/notifications';
import { themesActions } from 'pages/themes/containers/themesContainer/actions/themes';
import 'react-notifications-component/dist/theme.css';
import 'assets/sass/style.scss';

class App extends Component {
  componentDidMount() {
    this.props.dispatch(themesActions.fetchOrganization());
    this.services_notifications_timer = setInterval(
      () => this.props.dispatch(notificationActions.fetchServicesNotifications()),
      FETCH_SERVICES_NOTIFICATION_TIMER
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.is_loading === false &&
      this.props.username !== '' &&
      prevProps.is_loading !== this.props.is_loading
    ) {
      configurePanelApp(this.props.dispatch);
    }
  }

  componentWillUnmount() {
    this.services_notifications_timer = null;
  }

  render() {
    return (
      <Router history={history}>
        <ErrorBoundary>
          {this.props.username === '' && this.props.is_loading === false ? <Redirect to={'/login'} /> : ''}
          {this.props.panel_role === 'panel_user' &&
          (this.props.path_name === '/users' ||
            this.props.path_name === '/pricing' ||
            this.props.path_name === '/themes' ||
            this.props.path_name === '/settings') ? (
            <Redirect to={'/'} />
          ) : (
            ''
          )}
          {this.props.is_loading ? <FullPageLoader /> : <Routes panel_role={this.props.panel_role} />}
        </ErrorBoundary>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { is_loading, username } = state.USER_SETTINGS;
  const { roles } = state.USER_ROLES;
  let panel_role = roles['panel_role'];
  let path_name = window.location.pathname;
  return {
    is_loading,
    username,
    panel_role,
    path_name
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ notificationActions, themesActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
