import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import 'pages/login/index.scss';
import EdlyArbisoftLogo from 'assets/images/edly-by-arbisoft.png';
import EdlyLoginIllustration from 'assets/images/edly-login-illustration.svg';
import EdlyLoginLamp from 'assets/images/edly-login-lamp.svg';
import { PANEL_BASE_DOMAIN } from 'config';
import { getPanelBackendUrl } from 'helpers';
import { themesService } from 'services';

const Login = (props) => {
  const [subDomain, setSubDomain] = useState('');
  const [loginError, setLoginError] = useState(false);
  const { username } = props;

  const handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem('subDomain', subDomain);
    const panelBackendUrl = getPanelBackendUrl();

    themesService
      .fetchOrganizations()
      .then(() => {
        if (panelBackendUrl) {
          window.location = panelBackendUrl + 'login';
        }
      })
      .catch((error) => {
        if (!error.response || error.response.status === 500) {
          setLoginError(true);
        } else {
          if (panelBackendUrl) {
            window.location = panelBackendUrl + 'login';
          }
        }
      });
  };

  return (
    <Fragment>
      {username ? <Redirect to={{ pathname: '/dashboard' }} /> : null}
      <h1>{localStorage.getItem('edx_username')}</h1>
      <div className="login-page-content">
        <img src={EdlyArbisoftLogo} alt="Edly by arbisoft" className="login-logo" width="116" height="80" />
        <div className="login-illustration-container">
          <div className="login-lamp-container">
            <img src={EdlyLoginLamp} alt="Panel login" className="login-lamp" />
          </div>
          <img src={EdlyLoginIllustration} alt="Edly Illustration" className="login-illustration" />
        </div>
        <div className="login-form-container">
          <h1>Hey you, welcome back</h1>
          <div className="form-group">
            <label htmlFor="accountName">Account Name</label>
            <div className="account-name-field">
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  id="subDomain"
                  name="subDomain"
                  className="form-control"
                  placeholder="Account name here"
                  value={subDomain}
                  onChange={(event) => {
                    setSubDomain(event.target.value);
                    setLoginError(false);
                  }}
                />
                <span className="domain-name">{PANEL_BASE_DOMAIN}</span>
              </form>
            </div>
            {loginError && <span className="text-error">This organization does not exist.</span>}
          </div>
          <div className="cta-container">
            <button className="btn btn-lg btn-primary" onClick={handleSubmit}>
              Next
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

function mapStateToProps(state) {
  const { username } = state.USER_SETTINGS;
  return {
    username
  };
}

export default connect(mapStateToProps)(Login);
