import React from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const QuickStats = (props) => {
  const {
    monthly_active_quick_stats_data,
    enrolled_courses_quick_stats_data,
    course_completions_quick_stats_data
  } = props;

  const currentMonthMaus = monthly_active_quick_stats_data.length > 1 ? monthly_active_quick_stats_data[1] : 0;
  const previousMonthMaus = monthly_active_quick_stats_data.length > 1 ? monthly_active_quick_stats_data[0] : 0;
  const mausPercentage = ((currentMonthMaus - previousMonthMaus) / previousMonthMaus) * 100;

  const currentMonthEnrolledCourses =
    enrolled_courses_quick_stats_data.length > 1 ? enrolled_courses_quick_stats_data[1] : 0;
  const previousMonthEnrolledCourses =
    enrolled_courses_quick_stats_data.length > 1 ? enrolled_courses_quick_stats_data[0] : 0;
  const enrolledCoursesPercentage =
    ((currentMonthEnrolledCourses - previousMonthEnrolledCourses) / previousMonthEnrolledCourses) * 100;

  const currentMonthCourseCompletions =
    course_completions_quick_stats_data.length > 1 ? course_completions_quick_stats_data[1] : 0;
  const previousMonthCourseCompletions =
    course_completions_quick_stats_data.length > 1 ? course_completions_quick_stats_data[0] : 0;
  const courseCompletionsPercentage =
    ((currentMonthCourseCompletions - previousMonthCourseCompletions) / previousMonthCourseCompletions) * 100;

  return (
    <div>
      <div className="dashboard-header">
        <h2>Quick Stats</h2>
      </div>
      <div className="stats-block">
        <div className="stats-box">
          <h1>{currentMonthMaus}</h1>
          <span className="sub-title">
            Monthy Active Users{' '}
            <span className="tool-tip">
              <FontAwesomeIcon icon={Icons.faQuestionCircle} />
              <span className="tip-content">
                Monthly active users (MAUs) refers to the number of unique users who sign in to your site per calendar
                month. This includes users who create an account for the first time and users who access your site while
                already logged in.
              </span>
            </span>
          </span>
          {previousMonthMaus > 0 && (
            <strong className={'number ' + (mausPercentage < 0 ? 'fails' : 'success')}>
              <FontAwesomeIcon icon={mausPercentage < 0 ? Icons.faArrowDown : Icons.faArrowUp} />
              {Math.abs(mausPercentage).toFixed(2)}%
            </strong>
          )}
        </div>

        <div className="stats-box">
          <h1>{currentMonthEnrolledCourses}</h1>
          <span className="sub-title">
            Enrolled in Courses{' '}
            <span className="tool-tip">
              <FontAwesomeIcon icon={Icons.faQuestionCircle} />
              <span className="tip-content">
                Total number of users who are enrolled in a course per calendar month.
              </span>
            </span>
          </span>
          {previousMonthEnrolledCourses > 0 && (
            <strong className={'number ' + (enrolledCoursesPercentage < 0 ? 'fails' : 'success')}>
              <FontAwesomeIcon icon={enrolledCoursesPercentage < 0 ? Icons.faArrowDown : Icons.faArrowUp} />
              {Math.abs(enrolledCoursesPercentage).toFixed(2)}%
            </strong>
          )}
        </div>

        <div className="stats-box">
          <h1>{currentMonthCourseCompletions}</h1>
          <span className="sub-title">
            Course Completions{' '}
            <span className="tool-tip">
              <FontAwesomeIcon icon={Icons.faQuestionCircle} />
              <span className="tip-content">Total number of users who have completed a course per calendar month.</span>
            </span>
          </span>
          {previousMonthCourseCompletions > 0 && (
            <strong className={'number ' + (courseCompletionsPercentage < 0 ? 'fails' : 'success')}>
              <FontAwesomeIcon icon={courseCompletionsPercentage < 0 ? Icons.faArrowDown : Icons.faArrowUp} />
              {Math.abs(courseCompletionsPercentage).toFixed(2)}%
            </strong>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    monthly_active_quick_stats_data,
    enrolled_courses_quick_stats_data,
    course_completions_quick_stats_data
  } = state.QUICK_STATS;

  return {
    monthly_active_quick_stats_data,
    enrolled_courses_quick_stats_data,
    course_completions_quick_stats_data
  };
}

export default connect(mapStateToProps)(QuickStats);
