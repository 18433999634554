import { pricingConstants } from 'pages/pricing/containers/pricingContainer/constants';
import { pricingService } from 'services';
import { messageNotification, validateLoginSession } from 'helpers';

const fetchPricing = () => {
  return (dispatch) => {
    pricingService
      .fetchPricing()
      .then((pricing) => {
        Promise.resolve(
          dispatch({
            type: pricingConstants.FETCH_PRICING_SUCCESS,
            payload: pricing.data
          })
        );
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        if (error.response) {
          messageNotification('danger', 'There was an error fetching site pricing', error.response);
        }
      });
  };
};

export const pricingActions = {
  fetchPricing
};
