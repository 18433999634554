import React from 'react';

import { MonthlyActiveUsers } from 'pages/dashboard/containers/monthlyActiveUsers/monthlyActiveUsers';
import QuickStats from 'pages/dashboard/containers/quickStats/quickStats';
import NotificationsListing from 'pages/notifications/containers/notifications/components/notificationsListing';
import SeeAllAppsSlider from 'pages/seeAllApps/components/seeAllAppsSlider';
import Video from 'components/video';
import 'pages/dashboard/index.scss';

const Dashboard = (props) => {
  return (
    <div className="row">
      <NotificationsListing />
      <QuickStats />
      <MonthlyActiveUsers />
      <SeeAllAppsSlider />
      <Video />
    </div>
  );
};

export default Dashboard;
