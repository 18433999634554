import React, { Component } from 'react';
import 'pages/pricing/index.scss';

const moment = require('moment');

class PricingPlan extends Component {
  render() {
    const { pricing, maus_data } = this.props;

    if (pricing === null) {
      return <p>There is no pricing plan available.</p>;
    }

    const currentMonth = moment();
    const currentMonthMaus = maus_data.length > 1 ? maus_data[1] : 0;
    const previousMonthMaus = maus_data.length > 1 ? maus_data[0] : 0;
    let currentMonthTier,
      previousMonthTier = '';

    pricing.forEach((pricingTier, index) => {
      if (currentMonthMaus >= pricingTier.mau_range_start && currentMonthMaus <= pricingTier.mau_range_end) {
        currentMonthTier = pricingTier.mau_range_start + ' - ' + pricingTier.mau_range_end;
      }
      if (previousMonthMaus >= pricingTier.mau_range_start && previousMonthMaus <= pricingTier.mau_range_end) {
        previousMonthTier = pricingTier.mau_range_start + ' - ' + pricingTier.mau_range_end;
      }
    });

    return (
      <ul className="pricing-list">
        <li className="highlight">
          <div className="col-1">
            <h2>Current month’s Monthly Active Users: </h2>
            <p>As of {currentMonth.format('LL')}</p>
          </div>
          <div className="col-2">
            <strong className="number">{currentMonthMaus}</strong>
          </div>
        </li>
        <li>
          <div className="col-1">
            <h2>Current month’s MAU tier</h2>
          </div>
          <div className="col-2">
            <strong className="number">{currentMonthTier}</strong>
          </div>
        </li>
        <li className="highlight">
          <div className="col-1">
            <h2>Last month’s Monthly Active Users:</h2>
          </div>
          <div className="col-2">
            <strong className="number">{previousMonthMaus}</strong>
          </div>
        </li>
        <li>
          <div className="col-1">
            <h2>Last month’s MAU tier</h2>
          </div>
          <div className="col-2">
            <strong className="number">{previousMonthTier}</strong>
          </div>
        </li>
      </ul>
    );
  }
}

export default PricingPlan;
