import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { notificationActions } from 'pages/notifications/containers/notifications/actions/notifications';
import { getEdlyServiceUrl, getWordpressBaseUrl, getEcommerceBaseUrl } from 'helpers';
import EdlyArbisoftLogo from 'assets/images/edly-white-logo.png';
import MailChimpLogo from 'assets/images/mail-chimp-logo.png';
import ZendeskLogo from 'assets/images/zendesk-logo.png';
import GoogleAnalyticsLogo from 'assets/images/google-analytics-logo.png';
import 'components/header/index.scss';

const Header = (props) => {
  const { organization, services_notifications, display_status, dispatch } = props;

  const wordPressUrl = getEdlyServiceUrl(organization, 'wordpress');
  const lmsUrl = getEdlyServiceUrl(organization, 'lms');
  const studioUrl = getEdlyServiceUrl(organization, 'studio');
  const ecommerceUrl = getEdlyServiceUrl(organization, 'ecommerce');
  const mailChimpUrl = getEdlyServiceUrl(organization, 'mailchimp');
  const zenDeskUrl = getEdlyServiceUrl(organization, 'zendesk');
  const googleDataStudioUrl = getEdlyServiceUrl(organization, 'google_data_studio');

  const handleServicesNotificationsDisplayStatus = (notification_id) => {
    dispatch(notificationActions.updateServicesNotificationsStatus(notification_id, false));
  };

  const notifications = services_notifications.map((notification, index) => {
    return (
      <div key={index} className={display_status[notification.id] ? ' alert alert-' + notification.type : 'hidden'}>
        <FontAwesomeIcon icon={Icons.faExclamationCircle} className="icon" />
        <span className="dismiss" onClick={() => handleServicesNotificationsDisplayStatus(notification.id)}>
          Dismiss <FontAwesomeIcon icon={Icons.faTimes} />
        </span>
        <p>
          {notification.message}.
          <span className={notification.type === 'maintenance' ? '' : 'hidden'}>
            {' '}
            The service will be unavailable from <strong>
              {new Date(notification.start_time).toUTCString()}
            </strong> to <strong>{new Date(notification.end_time).toUTCString()}</strong>
          </span>
        </p>
      </div>
    );
  });

  return (
    <header className="header">
      <Navbar bg="light" expand="lg">
        <div className="container" role="tablist">
          <Navbar.Brand href="/dashboard">
            <img src={EdlyArbisoftLogo} alt="edly by arbisoft" className="logo" height="33" width="52" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="line" />
            <span className="line" />
            <span className="line" />
            <span className="line" />
          </Navbar.Toggle>
          <Navbar.Collapse id="edly-navbar">
            <Nav>
              <a href={lmsUrl} className="nav-link" rel="noopener noreferrer" target="_blank">
                LMS
              </a>
              <a href={studioUrl} className="nav-link" rel="noopener noreferrer" target="_blank">
                Studio
              </a>
              {getWordpressBaseUrl() && (
                <Fragment>
                  <a href={wordPressUrl} className="nav-link" rel="noopener noreferrer" target="_blank">
                    Discovery
                  </a>
                  <a href={wordPressUrl + 'wp-admin'} className="nav-link" rel="noopener noreferrer" target="_blank">
                    Discovery Admin
                  </a>
                </Fragment>
              )}
              {getEcommerceBaseUrl() && (
                <Fragment>
                  <a href={ecommerceUrl + 'courses/'} className="nav-link" rel="noopener noreferrer" target="_blank">
                    E-Commerce
                  </a>
                </Fragment>
              )}

              {(mailChimpUrl !== '/#' || zenDeskUrl !== '/#' || googleDataStudioUrl !== '/#') && (
                <NavDropdown
                  id="basic-nav-dropdown"
                  title={
                    <span>
                      <FontAwesomeIcon icon={Icons.faTh} size="lg" />{' '}
                      <span className="sr-only">Toggle services drop down</span>
                    </span>
                  }
                >
                  <ul className="help-group">
                    {zenDeskUrl !== '/#' && (
                      <li>
                        <Nav.Link href={zenDeskUrl} target="_blank" rel="noopener noreferrer" className="dropdown-item">
                          <img src={ZendeskLogo} alt="Zendesk" />
                          Zendesk
                        </Nav.Link>
                      </li>
                    )}
                    {mailChimpUrl !== '/#' && (
                      <li>
                        <Nav.Link
                          href={mailChimpUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="dropdown-item"
                        >
                          <img src={MailChimpLogo} alt="Mailchimp" />
                          MailChimp
                        </Nav.Link>
                      </li>
                    )}
                    {googleDataStudioUrl !== '/#' && (
                      <li>
                        <Nav.Link
                          href={googleDataStudioUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="dropdown-item"
                        >
                          <img src={GoogleAnalyticsLogo} alt="Google Data Studio" />
                          Google Analytics
                        </Nav.Link>
                      </li>
                    )}
                  </ul>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <div className={`system-notification ${display_status ? '' : 'hidden'}`}>{notifications}</div>
    </header>
  );
};

function mapStateToProps(state) {
  const { organization } = state.ORGANIZATION;
  const { services_notifications, display_status } = state.SERVICES_NOTIFICATIONS;

  return {
    organization,
    services_notifications,
    display_status
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ notificationActions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
