export const settingConstants = {
  UPDATE_SETTINGS: 'UPDATE_USER_SETTINGS',
  FETCH_SETTINGS: 'FETCH_USER_SETTINGS',
  USER_SETTINGS_FAILURE: 'USER_SETTINGS_FAILURE',
  FETCH_COUNTRY_TIMEZONES: 'FETCH_COUNTRY_TIMEZONES',
  FETCH_USERNAME: 'FETCH_USERNAME',
  UPDATE_USER_PREFERENCES: 'UPDATE_USER_PREFERENCES',
  UPDATE_USER_PROFILE_IMAGE: 'UPDATE_USER_PROFILE_IMAGE',
  FETCH_USER_PREFERENCES: 'FETCH_USER_PREFERENCES',
  RESET_USER_PASSWORD: 'RESET_USER_PASSWORD',
  FETCH_USER_ROLES: 'FETCH_USER_ROLES',
  FETCH_USER_ROLES_FAILURE: 'FETCH_USER_ROLES_FAILURE',
  FETCH_Ecommerce_Login_Session: 'FETCH_Ecommerce_Login_Session',
  USER_SESSION_LOGOUT: 'USER_SESSION_LOGOUT',
  APPLICATION_LOADING: 'APPLICATION_LOADING',
  APPLICATION_LOADED: 'APPLICATION_LOADED'
};
