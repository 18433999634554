import React, { useState } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const MonthRangePicker = (props) => {
  const [startDate, setStartDate] = useState(props.start_date);
  const [endDate, setEndDate] = useState(props.end_date);

  return (
    <div className="date-box">
      <div className="box">
        <label className="text" htmlFor="startDate">
          Start Date:
        </label>
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            props.handleDateChange('maus_start_date', date);
          }}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          className="form-control small"
          id="startDate"
        />
      </div>
      <div className="box">
        <label className="text" htmlFor="endDate">
          End Date:
        </label>
        <DatePicker
          selected={endDate}
          onChange={(date) => {
            setEndDate(date);
            props.handleDateChange('maus_end_date', date);
          }}
          selectsEnd
          startDate={startDate}
          minDate={startDate}
          endDate={endDate}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          id="endDate"
          className="form-control small"
        />
      </div>
      <button
        onClick={(e) => {
          e.preventDefault();
          props.handleMonthRangeDataRefresh(startDate, endDate);
        }}
        className="btn btn-link refresh-link"
      >
        View <FontAwesomeIcon icon={Icons.faSyncAlt} />
      </button>
    </div>
  );
};

export default MonthRangePicker;
